<template>
    <section class="leading" :style="{ backgroundImage: `url('${background}')` }">
        <div class="cards">
            <h1 v-html="title"></h1>
            <div v-for="(panel, index) in panels" class="panel-card"
            v-bind:key="index">
                <div class="panel-content">
                    <h2>{{ panel.title }}</h2>
                    <p>{{ panel.summary }}</p>
                </div>
                <div class="panel-links">
                    <div><a @click="gotoPage(panel.join)" :title=panel.CTA class="std-button">{{ panel.CTA }}</a></div>
                    <div class="cta-link"><a @click="gotoPage(panel.explore)" title="Explore"><span class="underline">Explore</span></a></div>
            </div>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable-next-line */
import JoinCommunityPanel from "@/components/JoinCommunityPanel.vue";

export default {
    data () {
        return {
            title : 'Join a Community',
            background : './assets/img/join-community/JL_PLAZAGEN0531_02.jpg',
            panels: [
                {
                    title: 'Witnesses & Survivors Registry',
                    summary: 'Firsthand experiences of the 9/11 attacks and the 1993 World Trade Center bombing.',
                    CTA: 'Join Registry',
                    join: this.$siteURL.value + '/#/registration/survivor',
                    explore: this.$siteURL.value + '/#/survivors',
                },
                {
                    title: 'Rescue & Recovery Workers Registry',
                    summary: 'Stories from the rescue, recovery, and relief efforts after 9/11.',
                    CTA: 'Join Registry',
                    join: this.$siteURL.value + '/#/registration/worker',
                    explore: 'rescue-recovery-workers',
                },
                {
                    title: 'Memorials Registry',
                    summary: 'Memorials around the world that commemorate the victims of 9/11.',
                    CTA: 'Add a Memorial',
                    join: this.$siteURL.value + '/#/registration/memorial',
                    explore: this.$siteURL.value + '/#/memorials',
                }            
            ],        
            index: null
        }
    },
    methods: {
        gotoPage: function (newPage)  {
            if (newPage != '#') {
                if (newPage.indexOf('http') > -1) {
                    location.href = newPage;
                } else {
                    this.$router.push(newPage).catch();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    section {
        width: 100%;
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center right;
        background-size: cover;
    }

    h1 {
        color: $white-header-text;
        margin-top: 100px;
        grid-column: 1 / 4;
        margin-bottom: 20px;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        grid-gap: 55px;
        max-width: 1170px;
        margin: 104px auto 110px;
    }

    .panel-card {
        background-color: $white;
        padding: 55px 40px 15px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        opacity: .85;
        margin-bottom: 110px;

        h2 {
            min-height: 170px;
        }

        p {
            margin-bottom: 40px;
            color: $standard-text;
            font-family: Helvetica;
            font-size: 1.25rem;
            font-weight: 300;
            line-height: 1.75rem;            
        }
    }

    .panel-content {
        flex-grow: 1;
    }

    .cta-link {
        padding: 20px 0 30px 0;
    }

</style>