<template>
    <div class="selectorband">
        <div class="selector-leading">
            <AccountSearch  /> 
            <h2 v-on:click="gotoPage">Registries</h2> 
            <h1 v-html="this.title"></h1>
            <div class="summary">{{ this.summary }}</div>
            <!-- <div class="disclaimer">Please Note: This Registry is separate from the <a href="https://www1.nyc.gov/site/911health/index.page">World Trade Center Health Registry</a> and<br />the <a href="http://www.wcb.ny.gov/WTC12/WTC12.jsp">World Trade Center Registry of the New York State Workers&rsquo; Compensation Board</a>.</div> -->
        </div>
    </div>
</template>

<script>
import AccountSearch from "@/components/AccountSearchBlock.vue";

export default {
    components: {
        AccountSearch
    },    
    props: {
        title: { type:String, default: "Registries"},
        summary: { type:String, default: "A digital repository"}
    },
    methods: {
        gotoPage ()  {
            this.$router.push('/')
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
                });                
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .account-search-wrapper {
        float: right;
        margin-top: 55px;
        display: inline-grid;
    }

    .selector-leading {
        max-width: 1170px;
        margin: 45px auto 50px;
        min-width: 1000px;
        padding-top: 80px;

        h1 {
            max-width: 800px;
            letter-spacing: -0.375rem;
            line-height: 4.688rem;
        }

        h2 {
            color: $primary-blue;
            font-family: Helvetica;
            font-size: 1.125rem;
            font-weight: 700;
            cursor: pointer;
            min-width: 1000px;
            margin-left: 5px;
        }
        h2:hover {
            color: $black;
        }

        .summary {
            margin-left: 7px
        }
    }
</style>