<template>
    <div class="quotecard" @click="gotoPage(quote.link)"> 
        <div class="quote-content">
            <div class="quote-saying">&ldquo;{{ quote.text }}&rdquo;</div>
            <div class="quote-source">
                <div class="author">{{ quote.author }}</div>
                <div class="role">{{ quote.role }}</div>
                <div class="location">{{ quote.location }}</div>      
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'quote',
    props: {
        quote: {
            type: Object
        }
    },
    methods: {
        showQuote: function(delayValue) {
            this.$el.querySelectorAll('.quote-saying').forEach(function (x) {
                x.setAttribute('style', 'animation-delay:'+delayValue+'s; -webkit-animation-delay:'+delayValue+'s');
                x.classList.add("quote-animate");
            });
        } ,
        gotoPage(newPage) {
            location.href = newPage;
        }
    } 
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .quotecard {
        position: relative;
        display: flex;
        box-sizing: border-box;

        width: 23.125rem;
        height: 100%;
        border: 0.063rem solid $border-bottom;
        color: $black;
        font-weight: 300;
        background-color: $bg-color;
        cursor: pointer;
    }

    .quotecard:hover {
        color: $primary-blue;
    }

    .quotecard:hover .quote-saying {
        -webkit-text-stroke: 1px;
        //font-weight: 600;
        //-webkit-text-stroke: 1px #2e7db3;
        //text-shadow:0 0 2px #2e7db3, 0 0 2px #2e7db3, 0 0 2px #2e7db3;
        //text-shadow: 0 0 1px #2e7db3, 0 0 1px #2e7db3;
    }

    .quote-content {
        font-family: Helvetica;
        font-size: 2.063rem;
        letter-spacing: -0.016rem;
        line-height: 2.35rem;
        margin: 50px 40px 10px 30px;
        display: flex;
        flex-direction: column;
        pointer-events: none;
    }

    .quote-saying {
        flex-grow: 1;
        color: transparent;
    }

    .quote-animate {
        animation-iteration-count: 1;

        animation: blur 1.5s ease-out 1 forwards;
        -webkit-animation: blur 1.5s ease-out 1 forwards;    
    }

    .quote-source {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.938rem;
        font-weight: 300;
        letter-spacing: 0.031rem;
        line-height: 1.25rem;
        margin: 25px 0;
        min-height: 60px;

        .author {
            color: $primary-blue;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.081rem;
            text-transform: uppercase;
        }
    }

@keyframes blur {
	0%		{text-shadow:  0 0 100px #D6EAF8; opacity:0;}
	5%		{text-shadow:  0 0 90px #D6EAF8;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 50px #AED6F1;}
    80%		{text-shadow:  0 0 0px #99bbff; color: transparent;}
	85%		{opacity: 1; }
    90%     {color: #9494b8;}
	100%	{text-shadow:none;color:inherit;}
}

@-webkit-keyframes blur {
	0%		{text-shadow:  0 0 100px #D6EAF8; opacity:0;}
	5%		{text-shadow:  0 0 90px #D6EAF8;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 50px #AED6F1;}
    80%		{text-shadow:  0 0 0px #99bbff; color: transparent;}
	85%		{opacity: 1; }
    90%     {color: #9494b8;}
	100%	{text-shadow:none;color:#000;}
}

</style>
