<template>
    <div class="site-search">
        <form id="searchform" action="" autocomplete="off" @submit.prevent="processSearch">
            <input ref="searchbar" v-model="searchinput" class="searchbar" type="text" v-bind:style="{ width: setWidth }" placeholder="Search by name or location">
            <input value="searching..." type="submit" @mouseenter="hover=true; expandSearch();" @mouseleave="hover=false">
            <div class="search-select"> 
               <v-select class="search-selector"
                  v-model="searchtype"
                  :clearable="false"
                  :options="[
                      'Rescue & Recovery Workers',
                      'Witnessess & Surviviors',
                      'Memorials']">
                </v-select>
                <span class="within">within</span>
            </div>
        </form>
    </div>
</template>

<script>
  import vSelect from 'vue-select';   

  export default {
    components: {
        'v-select' : vSelect
    },          
    data() {
      return {
          hover: false,
          searchinput: '',
          searchtype: 'Rescue & Recovery Workers',
      }
    },
    props: {
      setWidth: { type: String, default: '290px'}
    },
    created: function() {
      if (this.$kioskmode.value) {
          this.setWidth = "95%";
      }
    },
    methods: {
          expandSearch: function() {
              if (this.hover) {
                  // when hovering over search icon, also expand the search bar
                  this.$refs.searchbar.focus();
              }
          },
          processSearch: function() {
            /* search exmaples:
              https://registries.911memorial.org/#/survivors/list/term=bob
              https://registries.911memorial.org/#/workers/list/term=matt%20lee%20c
              https://registries.911memorial.org/#/memorials/list/term=new%20york%20city
            */
           if (this.searchinput.trim().length > 0) {            
              // create the Search URL path
              var queryType;
              switch (this.searchtype.toLowerCase()) {
                case 'memorials':
                  queryType="memorials/list/";
                  break;
                case 'witnessess & surviviors':
                  queryType="survivors/list/";
                  break;
                default:
                  queryType= "workers/list/";
              }
          
              var searchQuery = this.$siteURL.value + '/#/' + queryType + 'term=' + this.searchinput;
                
              if (searchQuery.indexOf('http') > -1) {
                  location.href = searchQuery;
              } else {
                this.$router.push(searchQuery)
                  .catch(function (error) {
                      /* eslint-disable-next-line */
                      currentObj.output = error;
                  });                   
              }
           }
          }
      }  
    }
</script>

<style lang="scss">
@import "@/styles/setup/_vars.scss";

/* for vue-select */
@import "@/styles/vue-select/global/_variables.scss";
@import "@/styles/vue-select/global/_component.scss";
@import "@/styles/vue-select/global/_animations.scss";
@import "@/styles/vue-select/global/_states.scss";

@import "@/styles/vue-select/modules/_dropdown-toggle.scss";
@import "@/styles/vue-select/modules/_open-indicator.scss";
@import "@/styles/vue-select/modules/_clear.scss";
@import "@/styles/vue-select/modules/_dropdown-menu.scss";
@import "@/styles/vue-select/modules/_dropdown-option.scss";
@import "@/styles/vue-select/modules/_selected.scss";
@import "@/styles/vue-select/modules/_search-input.scss";
@import "@/styles/vue-select/modules/_spinner.scss";

input[type="text"] {
    height: 30px;
    display: inline-block;
    font-family:Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    border: none;
    outline: none;
    color: $primary-blue;
    padding: 3px;
    padding-right: 40px;
    top: 0;
    left: 0;
    background: none;
    z-index: 3;
    transition: width .4s cubic-bezier(0.000, 0.795, 0.000, 1.000);
    cursor: pointer;
    border-bottom: 1px solid $primary-blue;
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $primary-blue;
    opacity: 1; /* Firefox */
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $primary-blue;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $primary-blue;
  }

input[type="text"]:focus {
    z-index: 1;
    cursor: text;
    color: $search-text-focused;
    font-weight: 700;
    border-bottom: 1px solid $search-text-focused;
    
    + input[type="submit"] {
      opacity: 1;
    }
} 

input[type="submit"] {
    height: 60px;
    width: 60px;
    display: block;
    color: #29a1f1;
    float: right;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAABXUlEQVRIid2VMW4CMRBFTWsK5v+ZIlvBEZIWOAHcCcItkoI+6baHgosAKWgCV0jkFNgSIsLsLoqQMpK7r3l/Z+y/zt2jAAxJmaiiVMU2npKUCYBB48YiIqqcmzHkjirnIiK1mpPsm2F9bIK9GWcAxt77wntfABiZ8dmMn1GzJtmv1BxAxwyb6G4pIr3MV3ZVuUgQAJ2rgDQWVS6dc60KnloJosr5NfeD6OaQc35eItKLowwAhheFpEzj8mZVm6cy48yMgZTpRZEqyuhiVBcQFx9UUeYAWzOGdrv9UBfgvS8iYJsDfJgxeO+L5gDucoA0onFdAIBxvCCriyJSJrcvGS85F+ma7htc04MqvkTkKSu+5aGZ4a2KG0k5FKOim9GeRAVDBTPHimG3OQu70e+wO77e01MZAqBzLa5V8W2Gd+ecawSJoLMfDndmWJnhVUQeT7WNIXXqf0H+rPnd6gc2Q5KYa5pwPgAAAABJRU5ErkJggg==) center center no-repeat;
    filter: invert(60%) sepia(29%) saturate(2686%) hue-rotate(176deg) brightness(92%) contrast(84%);
    text-indent: -1000px;
    border: 0;
    position: absolute;
    top: -14px;
    right: -16px;
    z-index: 2;
    cursor: pointer;
    opacity: 0.9;
    transition: opacity .4s ease;
  }

  input[type="submit"]:hover {
    opacity: 1;
  }

  .search-selector  {
    float: right;
    margin-top: 2px;
  }

  .within {
    display: inline-block;
    margin: 10px 0 5px 0;
    color: $summary;
    font-size: 1rem;
    font-weight: 500;
    float: right;
  }
</style>