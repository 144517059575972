<template>
    <div class="hero-container">
        <div class="leading">
            <joinLoginSearch></joinLoginSearch>
            <h1>{{ this.title }}</h1>
            <div class="summary">{{ this.summary }}</div>
        </div>
    </div>
</template>

<script>
import AccountSearch from "@/components/AccountSearchBlock.vue";

export default {
    components: {
        'joinLoginSearch' : AccountSearch
    },    
    props: {
        title: { type:String, default: "Registries"},
        summary: { type:String, default: "A digital repository"}
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .account-search-wrapper {
        float: right;
        padding: 0;
        display: inline-grid;
    }
    
    .hero-container {
        h1 {
            min-width: 1000px;
            
        }

        .leading {
            max-width: 1170px;
            margin: 45px auto 50px;
            min-width: 1000px;
            padding-top:80px;
        }

        .summary {
            margin-left: 7px;
        }
    }
</style>