import { render, staticRenderFns } from "./pgHome.vue?vue&type=template&id=9cee0068&"
import script from "./pgHome.vue?vue&type=script&lang=js&"
export * from "./pgHome.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./pgHome.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports