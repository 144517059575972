<template>
  <section v-if="displayProfileGraph">
        <div class="graphsection bordertop">  
            <graph-group            
                v-for="(groupInfo, index) in groups" 
                :groupData="groupInfo"
                :graphScale="displayFactor"
                v-bind:key="index"
                @changeGroup="groupClick"
                @updateTip="updateTip"
                >
            </graph-group>
        </div>
   </section>
</template>

<script>
import ProfileBarGraphRow from "@/components/ProfileGraphItem.vue";

export default {
  components: {
    'graph-group' : ProfileBarGraphRow
  },  
  data() {
    return {
        grouplocation: 'All Locations',
        profilesTotal : '',
        profilesDescription : 'Registered Registry Profiles',       
        groups: [],
        tipCount: 0, 
        tip: '',
        tipInfo: '',
        tipAbout: '',
        displayFactor: 0,
        displayProfileGraph: true,
        }
        // return {
        //     grouplocation: 'Pentagon',
        //     profilesTotal : '15,830',
        //     profilesDescription : 'Registered Registry Profiles',       
        //     groups: [
        //         { "groupname" : "Medical Workers", "total" : "7", "displaycount" : 2},
        //         { "groupname" : "Firefighters", "total" : "129", "displaycount" : 30},
        //         { "groupname" : "Military Members", "total" : "35", "displaycount" : 9},
        //         { "groupname" : "Law Enforcement Personnel", "total" : "84", "displaycount" : 21},
        //         { "groupname" : "Relief Workers and Volunteers", "total" : "16", "displaycount" : 4},
        //         { "groupname" : "Government Agency Workers", "total" : "42", "displaycount" : 11},
        //         { "groupname" : "Other", "total" : "39", "displaycount" : 10},
        //         { "groupname" : "Public and Private Sector Professionals", "total" : "3", "displaycount" : 1}
        //     ],
        //     tipcount: 543, 
        //     tip: 'people have registered under Building & Construction'
        //     }
    },
    mounted () {
       this.getGraphProfileData(this.grouplocation);
    },   

    methods: {
        getGraphProfileData(forLocation) {
            // getting '-all.json');
            this.axios.get('/assets/json-main/'+forLocation.replace(/ /g,"-").toLowerCase()+'-all.json')
                .then((response) => {
                    this.displayProfileGraph = true;
                    this.tip = response.data.tip;
                    this.tipCount = response.data.tipcount;
                    this.tipAbout = response.data.tipabout;
                    this.groups = response.data.groups;
                    this.displayFactor = response.data.displayFactor;
                    this.$emit('graphScale', this.displayFactor);
                    this.$emit('updateProfileTotal', response.data.profilesTotal, response.data.profilesDescription);
            })
            .catch();
        },
        getProfileTotalbyName(theGroup) {
            var indx = this.groups.findIndex(x => x.groupname === theGroup);
            // console.log("getProfileTotalbyName found "+theGroup+": "+indx+":");
            return this.groups[indx].total;  
        },
        turnoffGraphDisplay (value) {
            this.displayProfileGraph = value;
        },
        groupClick: function (ev) {
            this.$emit("changeGroup", ev);
        },
        updateTip: function (tipcount, tipgroup) {
            // console.log ("ProfileBarGram received updateTip" + tipcount +":"+tipgroup);
            this.$emit("tipUpdate", tipcount, tipgroup);
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .graphsection {
        vertical-align: middle;
        padding-bottom: 50px;
        grid-column: 1;

        .tipsection {
            grid-column: 2;
        }
    }

    .bordertop {
        border-top: 0.125rem solid $graph-border;
    }
</style>
