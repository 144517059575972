<template>
    <div class="tipsection bordertop">
        <div class="tip-aside">
            <div>
                <a :href="this.registerURL" aria-label="Join" title="Join" class="std-button">Join Registry</a>
            </div>       
            <transition name="basicfade" mode="out-in">
                <h3 :key="this.tipCount">{{ this.tipCount }}</h3>
            </transition>
            <transition name="basicfade" mode="out-in" :duration="500">        
                <div class="tip-info" :key="this.tipInfo" v-html="this.tipInfo"></div>
            </transition>
        </div>
        <div class="tip-about">
            <transition name="basicfade" mode="out-in" :duration="600">        
                <span :key="this.tipAbout">{{ this.tipAbout }}</span>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tipCount: String, 
        tipInfo: String,
        tipAbout: String
    },
    data () {
        return {
            registerURL : this.$siteURL.value + '/#/registration'
        }
    },           
    mounted: function() {
        // check if registration goes to RRW registration
        if (this.$route.name.indexOf('worker') >= 0) {
            this.registerURL = this.$siteURL.value + '/#/registration/worker';
        }        
    } 
}
</script>


<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";
    .bordertop {
        border-top: 0.125rem solid $graph-border;
    }

    .tipsection {
        grid-column: 2;
        vertical-align: top;
    }    
    
    .tip-aside {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.938rem;
        font-weight: 300;
        letter-spacing: 0.031rem;
        padding: 54px 0 11px 0;
        text-align: center;

        h3 {
            color: $black;
            font-family: "GothamBold";
            font-size: 2.063rem;
            font-weight: 700;
            letter-spacing: -0.016rem;
            line-height: 2.5rem;
            height: 40px;
            margin-top: 54px;
        }

        .tip-info {
            line-height: 1.25rem;
            height: 60px;
            padding-top: 11px;
        }
    }

    .tip-about {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.125rem;
        padding-top: 11px;
        margin-bottom: 50px;
    }

</style>