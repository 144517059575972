<template>
  <section class="voicesection">
    <div>
        <join-cta />
        <h1 v-html="title"></h1>
    </div>
    <div class="summary">{{ summary }}</div>
        
    <vue-photo-gallery 
        ref="childPhoto"
        class="childcomponent"
        :items="this.photos"
        :slideshow="false"
        :gallery="false"
        :loop="true" 
        :index="index"
        @close="index = null"
    >
        <template #icon-next><i class="fa galleryui">&#xf054;</i></template>
        <template #icon-previous><i class="fa galleryui">&#xf053;</i></template>
        <template #close><i class="fa galleryui">&#xf00d;</i></template>
    </vue-photo-gallery>

    <div class="cards images-wrapper"
        v-observe-visibility="{
            callback: visibilityChanged,
            once: true}" >
      <div
        class="photocard"
        v-for="(image, imageIndex) in photos"
        :key="imageIndex"
        @click="index = imageIndex"
        :style="{ backgroundImage: 'url(' + image.src + ')' }"
      >
        <div class="photo-enlarge">
            Enlarge Image</div>
            <img class="photo-img" :src="image.thumbnail" :alt="image.alt" />
        </div>
    </div>

  </section>
</template>

<script>
import CoolLightBox from '@/components/CoolLightBox.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import joinRegistryBlock from '@/components/JoinRegistry.vue';

export default {
    components : {
        'vue-photo-gallery' : CoolLightBox,
        'join-cta' : joinRegistryBlock,
        /* eslint-disable-next-line */
        VueObserveVisibility
    },
    data: function () {
        return {
            title : 'Stories of<br/>Recovery and Hope',
            summary : '',
            photos: [
                {
                    src: '',
                    thumbnail: '',
                    w: 600,
                    h: 400,
                    title: 'caption',
                    url: 'url link'
                }
            ],
            index: null
        };
    },
    mounted () {
        this.getGalleryData();
    },
    afterRouteUpdate () {
        this.getGalleryData();
    },   
    methods: {
        getGalleryData() {
            var index = Math.round(Math.random() * 2);
            var routeName = this.$route.name;
            if (routeName == 'main' || routeName == '') {
                routeName = 'home';
            }
            //console.log ("Gallery loading: " + this.$route.name + '-stories-' + index.toString() + '.json');
            this.axios.get('/assets/json-main/' + this.$route.name + '-stories-' + index.toString() + '.json')
                .then((response) => {
                    this.title = response.data.title;
                    this.summary = response.data.summary;
                    this.photos = response.data.photos;
                })
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
                });
        },
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                this.$el.querySelectorAll('.photo-img').forEach(x=> x.classList.add("desaturate"));
            }
        }
    },      
}
</script>

<style lang="scss">
    .voicesection {
        display: grid;
        max-width: 1170px;
        margin: 100px auto 50px;
    }

    .galleryui {
        font-size: 48px;
        font-family: fontawesome;
        color: #fff;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        grid-gap: 30px;
        min-height: 900px;
    }

    .photocard {
        position: relative;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
        background: none !important;
    }

    .photo-img {
        height: 278px;
        width: 100%;
        cursor: pointer;
        object-fit: cover;
        filter: gray;
        filter: grayscale(100%); 
        -webkit-filter: grayscale(1);

        max-width: 100%; 
        -moz-transition: all 0.6s ease-in-out;  
        -webkit-transition: all .6s ease-in-out;  
        transition: all 0.6s ease-in-out;
    }

    .photo-img:hover {
        filter: none;
        -webkit-filter: grayscale(0);
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    .photocard:hover div {
        visibility: visible;
        opacity: 0.75;
    }

    .photo-enlarge {
        text-align: center;
        position: absolute;
        bottom: 131px;
        width: 100%;
        padding: 10px 0;
        background-color: #000000;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.001rem;
        text-transform: uppercase;
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.4s, visibility  0.3s;
        transition: opacity 0.4s, visibility  0.3s;
        pointer-events: none;
    }

    .cool-lightbox-caption{
        margin: 10px auto 5px !important;
        width: 80% !important;
        max-width: unset !important;
    }

    .cool-lightbox-caption h6 {
        font-family: Helvetica !important;
        font-size: .875rem !important;
        letter-spacing: .018rem !important;
    }

  @keyframes toGray {
        0%    { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
        25%   { -webkit-filter: grayscale(25%); filter: grayscale(25%); }
        50%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
        75%   { -webkit-filter: grayscale(75%); filter: grayscale(75%); }
        100%  { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
  }

</style>
