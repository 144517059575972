import Vue from 'vue';
import HomePg from './components/pgHome.vue';
import RescueRecoveryWorkers from './components/pgRescueRecoveryWorkers.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTimers from 'vue-timers';

Vue.config.productionTip = false;
Vue.config.silent = true;                       /* set to true to surpress all logs and warnings */
Vue.prototype.$kioskmode  = {value: false};
Vue.prototype.route = 'home';
Vue.prototype.$siteURL = {value: ""};           /* https://test911memo.cloudapp.net */ 
Vue.prototype.$userAuthenticated = {value: false}; 

Vue.use(VueObserveVisibility);
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
Vue.use(VueTimers);

let initialized = false;

export default function() {

    if (initialized) return;

    Vue.use(VueRouter);

    const routes = [
        { path: '/', component: HomePg, name: 'home' },
        { path: '/#/', component: HomePg, alias: '/' },
        { path: '/home', component: HomePg, alias: '/'},
        { path: '/#/home', component: HomePg, alias: '/'},
        { path: '/#/workers', component: RescueRecoveryWorkers, name: 'workers' },
        { path: '/workers', component: RescueRecoveryWorkers, alias: '/#/workers'},        
        { path: '/rescue-recovery-workers', component: RescueRecoveryWorkers, redirect: { name: 'workers' }}
    ];
    
    // IMPORTANT: 
    // change mode to 'hash' to run independently
    // but mode needs to be 'history' BEFORE compiling to run within Registries site
    const router = new VueRouter({ 
        mode: 'history',
        routes 
    });
    Vue.mixin({ router });

    router.beforeEach((to, from, next) => {
        // Redirect if fullPath begins with a hash (ignore hashes later in path)  
        if (to.hash === '#/workers' && to.name == 'home' ) {

            if (to.path === '/') {
                next ({name: 'workers'});
                return;
            }
        }        
        next();
    });

    initialized = true;
}
