<template>
  <section>
    <h1 v-html="title"></h1>
    <div class="summary">{{ summary }}</div>
    <div class="cards">
        <article
            v-for="(quote) in quotes"
            v-bind:key="quote.id"
             v-observe-visibility="{
                callback: visibilityChanged,
                once: true
            }">
                <quotes-gallery 
                    ref="childQuote" 
                    :quote="quote"
                 />
        </article>
    </div>
  </section>
</template>

<script>
import QuoteItem from './QuoteItem.vue';
/* eslint-disable-next-line */
import VueObserveVisibility from 'vue-observe-visibility';

var timeDelays = [ 0.1, 0.2, 0.3, 0.4, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9]

export default {
    components : {
        'quotes-gallery' : QuoteItem
    },
    data () {
        return {
            title : 'Stories of Rescue & Recovery',
            summary : '',
            quotes: []
        }
    },
    created () {
        // previously did a random shuffle of when the quotes appeared
        // shuffleArray(timeDelays);
    },
    mounted () {
        this.getQuotesData();
    },   
    methods: {
        getQuotesData() {
            // load in the quotes data
            this.axios.get('/assets/json-main/' + this.$route.name  + '-quotes.json')
                .then((response) => {
                    this.title = response.data.title;
                    this.summary = response.data.summary;
                    this.quotes = response.data.quotes;
                })
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
                });
        },
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                this.$refs.childQuote.forEach(function (x, index) {
                    x.showQuote(timeDelays[index]);
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    section {
        display: grid;
        max-width: 1170px;
        margin: 100px auto 50px;
    }
    
    .cards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
        grid-gap: 30px;
    }

    .photocard {
        position: relative;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
    }
</style>
