import { render, staticRenderFns } from "./JoinCommunityPanel.vue?vue&type=template&id=14589772&"
import script from "./JoinCommunityPanel.vue?vue&type=script&lang=js&"
export * from "./JoinCommunityPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports