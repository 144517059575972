<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import initRouter from './initRouter.js';

  initRouter();

  export default {
    name: 'app',
    props: {
          siteurl: { type:String, default: ""},
          username: { type:String, default: ""},
          kioskmode: { type:String, default: ""},
      },
      created: function() {
          // update global site URL with value passed in through component
          this.$siteURL.value = this.siteurl;
          if (this.username != "") { 
            this.$userAuthenticated.value = true; }

          if (this.kioskmode == "true") { 
            this.$kioskmode.value = true; }

          // using CSS variables to change between website and kiosk's dark theme 
          if (this.$kioskmode.value) {
              document.documentElement.style.setProperty('--dark-header-text', '#2e7db3'); 
              document.documentElement.style.setProperty('--white-header-text', '#2e7db3'); 
              document.documentElement.style.setProperty('--header-copy', '#fff'); 
              document.documentElement.style.setProperty('--headline-title', '#fff');
              document.documentElement.style.setProperty('--feature-title', '#68bbdf');
              document.documentElement.style.setProperty('--feature-title-hover', '#fff');
              document.documentElement.style.setProperty('--standard-text', '#fff');
              document.documentElement.style.setProperty('--light-text', '#fff');
              document.documentElement.style.setProperty('--breakline', '#173143');
              document.documentElement.style.setProperty('--summary', '#fff');
              document.documentElement.style.setProperty('--bg-color', '#011523'); 
              document.documentElement.style.setProperty('--black-color', '#fff'); 
              document.documentElement.style.setProperty('--white-color', '#000'); 
              document.documentElement.style.setProperty('--main-background-color', '#011523'); 
              document.documentElement.style.setProperty('--text-shadow', 'rgba(255, 255, 255, 0.5)');
              document.documentElement.style.setProperty('--border-bottom', '#143d53');
              document.documentElement.style.setProperty('--graph-border', '#143d53');
          //  document.documentElement.style.setProperty('--stats-background', '#000');
              document.documentElement.style.setProperty('--button-background', '#');
              document.documentElement.style.setProperty('--button-text', '#2e7db3');
              document.documentElement.style.setProperty('--button-hover', '#2e7db3');
              document.documentElement.style.setProperty('--button-hover-text', '#fff');
              document.documentElement.style.setProperty('--selection-container', '#226088');
              document.documentElement.style.setProperty('--selection-text', '#fff');
              document.documentElement.style.setProperty('--figure-color', 'invert(40%) sepia(10%) saturate(563%) hue-rotate(162deg) brightness(95%) contrast(90%)');
              document.documentElement.style.setProperty('--figure-hover', 'invert(100%) sepia(100%) saturate(0%) hue-rotate(341deg) brightness(106%) contrast(105%)');
              document.documentElement.style.setProperty('--search-text-focused', '#fff');
              document.documentElement.style.setProperty('--search-dropdown', '#68bbdf');
              document.documentElement.style.setProperty('--search-arrow', '#226088');
              document.documentElement.style.setProperty('--leading-section', '0px 20px');
              document.documentElement.style.setProperty('--hero-feature-grid-columns', '192px 240px 5px');
              document.documentElement.style.setProperty('--hero-thumb-width','192px');
              document.documentElement.style.setProperty('--hero-thumb-height','110px');
              document.documentElement.style.setProperty('--hero-img-photo-height', '660px');
              document.documentElement.style.setProperty('--hero-feature-justify-content', 'center');
              document.documentElement.style.setProperty('--hero-grid-template-columns', '650px auto'); 
              document.documentElement.style.setProperty('--hero-feature-photo-max-width', '1175px');
              document.documentElement.style.setProperty('--panelcard-height', '525px');             
              document.documentElement.style.setProperty('--panelcard-article-img-height', '535px');
              document.documentElement.style.setProperty('--app-position', 'absolute');
              document.documentElement.style.setProperty('--app-top', '0');

          } else {
              document.documentElement.style.setProperty('--dark-header-text', '#333'); 
              document.documentElement.style.setProperty('--white-header-text', '#fff'); 
              document.documentElement.style.setProperty('--header-copy', '#333'); 
              document.documentElement.style.setProperty('--headline-title', '#333');
              document.documentElement.style.setProperty('--feature-title', '#2e7db3');
              document.documentElement.style.setProperty('--feature-title-hover', '#000');
              document.documentElement.style.setProperty('--standard-text', '#143d53');
              document.documentElement.style.setProperty('--light-text', '#6f6f6f');
              document.documentElement.style.setProperty('--breakline', '#6f6f6f');
              document.documentElement.style.setProperty('--summary', '#143d53');
              document.documentElement.style.setProperty('--bg-color', '#fff'); 
              document.documentElement.style.setProperty('--black-color', '#000'); 
              document.documentElement.style.setProperty('--white-color', '#fff'); 
              document.documentElement.style.setProperty('--main-background-color', '#fff'); 
              document.documentElement.style.setProperty('--text-shadow', 'rgba(0, 0, 0, 0.5)');
              document.documentElement.style.setProperty('--border-bottom', '#3b9ad9');
              document.documentElement.style.setProperty('--graph-border', '#ddd');
        //   document.documentElement.style.setProperty('--stats-background', '#008080');
              document.documentElement.style.setProperty('--button-background', '#fff');
              document.documentElement.style.setProperty('--button-text', '#2e7db3');
              document.documentElement.style.setProperty('--button-hover', '#2e7db3');
              document.documentElement.style.setProperty('--button-hover-text', '#fff');
              document.documentElement.style.setProperty('--selection-container', '#000');
              document.documentElement.style.setProperty('--selection-text', '#fff');
              document.documentElement.style.setProperty('--figure-color', 'invert(70%) sepia(54%) saturate(0%) hue-rotate(253deg) brightness(84%) contrast(95%)');
              document.documentElement.style.setProperty('--figure-hover', 'invert(100%) sepia(26%) saturate(0%) hue-rotate(300deg) brightness(99%) contrast(94%)');
              document.documentElement.style.setProperty('--search-text-focused', '#2e7db3');
              document.documentElement.style.setProperty('--search-dropdown', '#fff');
              document.documentElement.style.setProperty('--search-arrow', '#333');
              document.documentElement.style.setProperty('--leading-section', '20px 20px');
              document.documentElement.style.setProperty('--hero-feature-grid-columns', '170px 240px 5px');
              document.documentElement.style.setProperty('--hero-feature-photo-max-width', 'unset');
              document.documentElement.style.setProperty('--hero-thumb-width','170px');
              document.documentElement.style.setProperty('--hero-thumb-height','97px');              
              document.documentElement.style.setProperty('--hero-img-photo-height', '660px');
              document.documentElement.style.setProperty('--hero-feature-justify-content', 'right');
              document.documentElement.style.setProperty('--hero-grid-template-columns', '480px auto');
              document.documentElement.style.setProperty('--panelcard-height', '450px');             
              document.documentElement.style.setProperty('--panelcard-article-img-height', '460px');
              document.documentElement.style.setProperty('--app-position', 'relative');
              document.documentElement.style.setProperty('--app-top', '-80px');
          }
      }
  }
</script>

<style lang="scss">
@import "@/styles/setup/_reset.scss";
@import "@/styles/setup/_vars.scss";
@import "@/styles/setup/_defaults.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-background-color;
  position: $app-position;
  width: 100%;
  top: $app-top;  
}

</style>