<template>
    <div class="feature hvr-bubble-right" v-on:mouseover="updateMainImage" v-on:mouseleave="exitHover" v-on:click.capture="goto">
        <div class="headline">{{ panelInfo.headline }}</div>
        <div class="photocard">
            <div class="hero-band">
              {{ panelInfo.hover }}
            </div>
                <img class="hero-img" :src="panelInfo.thumbnail" :alt="panelInfo.summary" />
        </div>
        <div class="feature-info"> 
            <h3 class="feature-title">{{ panelInfo.title }}</h3>
            <p class="feature-desc">{{ panelInfo.summary }}</p>
            <h4 class="feature-cta">{{ panelInfo.CTA}}</h4>
        </div>
    </div>     
</template>

<script>
export default {
    props: {
        paneldata: { type: Object }
    },
    data () {
        return {
            panelInfo: {
                headline: "Explore Deeper",
                title: 'Witness & Survivors',
                summary: 'Firsthand experiences of the 9/11 attacks and the February 26, 1993 bombing.',
                CTA: 'Learn More',
                url: '#',
                thumbnail: '/assets/img/hero/cta-witness@2x.png',
                hover: 'Explore',
                mainImage: '/assets/img/hero/wtc-witnesses.jpg',
                credits: ''
            }
        }
    },
    mounted () {
        // initialize data with values passed through properties
        this.panelInfo = this.paneldata;
    },
    methods: {
        updateMainImage() {
            this.$emit ('updatehero', this.panelInfo.mainImage, this.panelInfo.credits);
        },
        exitHover() {
            this.$emit ('exithover', this.panelInfo.mainImage);
        },
        goto()  {
            if (this.panelInfo.url != "#") {
                //console.log ('inHeroFeaturePanel: '+this.panelInfo.url);
                if (this.panelInfo.url.indexOf('http') > -1) {
                    location.href = this.panelInfo.url;
                } else {
                    this.$router.push(this.panelInfo.url)
                        .catch(function (error) {
                            /* eslint-disable-next-line */
                            currentObj.output = error;
                        });                   
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .feature {
        display: grid;
        grid-template-columns: $hero-feature-grid-columns;
        grid-column-gap: 20px;
        justify-content: $hero-feature-justify-content;
        align-content: space-evenly;
        max-height: 600px;
        cursor: pointer;
    }

    .feature-info {
        pointer-events: none;
    }

    .feature-title {
        color:  $feature-title;
        font-family: "GothamBold";
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .feature-desc {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.8125rem;
        letter-spacing: 0.018rem;
        line-height: 1rem;
        margin: 12px 0;
    }

    .feature-cta {
        color: $primary-blue;
        font-family: Helvetica;
        font-weight: 700;
        font-size: 0.8125rem;
        pointer-events: initial;
        margin: 12px 0 12px;
    }


    .feature-more {
        color: $primary-blue;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        pointer-events: initial;
        margin: 12px 0 12px;
    }

    .headline {
        grid-column: 1 / 4;
        color: $headline-title;
        font-family: "GothamBold"; 
        font-size: 1.5rem; 
        line-height: 2.125rem; 
        margin-bottom: -5px;
        font-weight: 500;
        letter-spacing: -0.079rem;
        pointer-events: none;
    }

    .photocard {
        position: relative;
        display: block;
        width: $hero-thumb-width;
        height: $hero-thumb-height;
        box-sizing: border-box;
        overflow: hidden;
        pointer-events: none;
    }

    .hero-img {
        width: $hero-thumb-width;
        height: $hero-thumb-height;
        object-fit: cover;
        filter: gray;
        filter: grayscale(100%);
        -webkit-filter: grayscale(1);

        max-width: 100%; 
        -moz-transition: all 0.6s ease-in-out;  
        -webkit-transition: all 0.6s ease-in-out;  
        transition: all 0.6s ease-in-out;
        transform: translateZ(0);         
    }

    .feature:hover .hero-img {
        filter: none;
        -webkit-filter: grayscale(0);
    }

    .feature:hover .feature-title {
        color: $feature-title-hover;
    }

    .feature:hover  .hero-band {
        visibility: visible;
        opacity: 0.75;
    }

    .hero-band {
        text-align: center;
        position: absolute;
        bottom: 35px;
        width: 100%;
        padding: 10px 0;
        background-color: $black;
        text-shadow: 0 0 0.25rem $text-shadow;
        color: $white;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.081rem;
        text-transform: uppercase;
        
        z-index: 100;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.4s, visibility  0.3s;
        transition: opacity 0.4s, visibility  0.3s;
        pointer-events: none;
    }
    
    /* Bubble Right */
    .feature.hvr-bubble-right {
        vertical-align: middle;
        border-bottom: 1px solid $graph-border;
        position: relative;
        padding-bottom: 20px;
    }

    .hvr-bubble-right:before {
        /* pointer-events: none; */
        position: absolute;
        z-index: -1;
        content: '';
        border-style: solid;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: transform;
        transition-property: transform;
        top: calc(50% - 10px);
        right: 0;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent $bg-color;
    }

    .hvr-bubble-right:hover:before, .hvr-bubble-right:focus:before, .hvr-bubble-right:active:before {
        -webkit-transform: translateX(20px);
        transform: translateX(20px); 
        z-index: 999;
    }

    .hvr-bubble-right:first-child, :not(.hvr-bubble-right) + .hvr-bubble-right {
        border-top: 1px solid $graph-border;    
    }

</style>