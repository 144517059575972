import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=99270062&scoped=true&"
import script from "./FAQ.vue?vue&type=script&lang=js&"
export * from "./FAQ.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./FAQ.vue?vue&type=style&index=0&id=99270062&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\runtime\\componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "99270062",
  null
  ,true
)

export default component.exports