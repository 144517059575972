// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\css-loader\\dist\\cjs.js??ref--8-oneOf-1-1!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\loaders\\stylePostLoader.js!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\postcss-loader\\src\\index.js??ref--8-oneOf-1-2!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\postcss-loader\\src\\index.js??ref--8-oneOf-1-3!../../node_modules/sass-loader/lib/loader.js??ref--8-oneOf-1-4!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\cache-loader\\dist\\cjs.js??ref--0-0!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-loader\\lib\\index.js??vue-loader-options!./RegistriesHero.vue?vue&type=style&index=0&id=74daf131&lang=scss&scoped=true&");
if(typeof content === 'string') content = [[module.id, content, '']];
if(content.locals) module.exports = content.locals;
// add CSS to Shadow Root
var add = require("!C:\\usr\\local\\node_modules\\@vue\\cli-service-global\\node_modules\\vue-style-loader\\lib\\addStylesShadow.js").default
module.exports.__inject__ = function (shadowRoot) {
  add("4ed8b685", content, shadowRoot)
};