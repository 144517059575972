<template>
    <div class="circle-container">
        <div class="progress-circle__container">        
            <div class="progress-circle__percent">{{ percent }}<span class="progress-circle__percent-symbol">%</span>
                <div class="progress-circle__description">{{ description }}</div>
            </div>
            <svg class="progress-circle" viewBox="0 0 106 106" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-17.000000, -17.000000)">
                        <circle v-bind:stroke="borderColor" stroke-width="1" fill-rule="nonzero" cx="70" cy="70" r="50"></circle>
                        <path class="progress-circle__path" d="M70,120 C97.6142375,120 120,97.6142375 120,70 C120,42.3857625 97.6142375,20 70,20 C42.3857625,20 20,42.3857625 20,70 C20,97.6142375 42.3857625,120 70,120 Z" id="Oval-Copy" v-bind:stroke="currentColor" stroke-width="3" :stroke-dasharray="circle" fill-rule="nonzero" transform="translate(70.000000, 70.000000) rotate(-125.000000) translate(-70.000000, -70.000000) "></path>
                    </g>
                </g>
            </svg>
        </div>
    </div>    
</template>

<script>
export default {
    props: {
        stats: { type: Object }
    },
	data: function () {
        return {
            // percent: this.initialPercent,   <-- use to pass initial value through prop
            percent: 0,
            description: ''
        }
    },
    watch: {
        stats: function () {
            //// console.log("watch triggered: "+this.stats.percent+"% "+this.stats.description);
            this.percent = this.stats.percent;
            this.description = this.stats.description;
        }
    },
    mounted () {
        this.percent = this.stats.percent;
        this.description = this.stats.description;
    },
    methods: {
        showGraph: function(newPercent) {
            this.percent = newPercent;
        }
    },
    computed: {
        circle: function () {
            return ((this.percent / 100) * 100 * Math.PI) + ',9999';
        },
        borderColor: function () {
          return (this.$kioskmode.value ? "#143d53" : "#ccc");  
        },
        currentColor: function () {
            var hex = "#2e7db3";
            var lum = (100-this.percent)/100/1.5;

            if (this.$kioskmode.value) {
                hex = "#194664";
                lum = Math.abs(-2+(100-this.percent)/100*2); 
            }

            // validate hex string
            hex = String(hex).replace(/[^0-9a-f]/gi, '');
            if (hex.length < 6) {
                hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
            }
            lum = lum || 0;

            // convert to decimal and change luminosity
            var rgb = "#", c, i;
            for (i = 0; i < 3; i++) {
                c = parseInt(hex.substr(i*2,2), 16);
                c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                rgb += ("00"+c).substr(c.length);
            }
            return rgb;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .progress-circle {
    max-width:15rem;
    max-height:15rem;
    width:100%;
    transform: scaleX(-1) rotate(-55deg);
    
        &__percent {
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
        }

        &__container {
            display:inline-block;
            position:relative;
            color: $headline-title;
            font-family: "GothamMedium";
            font-size: 3.813rem;
            font-weight: 500;
            letter-spacing: -0.009rem;
            text-align: center;
            width: 240px;
            overflow: hidden;
        }

        &__percent-symbol {
            font-size: 2.25rem; 
        }  

        &__description {
            color: $black;
            font-family: Helvetica;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.25rem;
        }

        &__path {
            transition: 0.5s ease-in-out all;
        }

        &__button {
            text-decoration:none;
            margin-top: 24px;
            margin-left:24px;
            background: $stats-background;
            color:$white;
            padding: 18px 24px;
            border-radius: 4px;
            transition: 0.25s linear background;
            
            &:hover {
                cursor: pointer;
                background: darken($stats-background, 5%);
                transition: 0.25s linear background;
            }
        }
    }
</style>