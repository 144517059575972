<template>
    <section class="directory-container" :style="{ backgroundImage: `url('${background}')` }">
        <h1 v-html="title"></h1>
        <join-cta />
        <div class="summary" v-html="summary"></div>
        <search-bar setWidth="735px"></search-bar>
        <div class="directory-listing"
            v-observe-visibility="{
                    callback: visibilityChanged,
                    once: true}">
            <ul class="directory">
                <li
                    v-for="(profile, index) in directory"
                    v-bind:key="index"
                >
                <a :href=profile.link>
                    <ul class="profile">
                        <li ref="profileName" class="profile-name" :style="listItemStyle(index)">{{ profile.name }}</li>
                        <li class="profile-group">{{ profile.group }}</li>
                        <li class="profile-location">{{ profile.location }}</li>
                    </ul>
                </a>    
                </li>
            </ul>
        </div>
        <div class="photocredits" v-bind:class="{ 'non-kiosk' : !this.$kioskmode.value}" v-html="photocredits"></div>
    </section>
</template>

<script>
import SearchBar from '@/components/Search.vue';
import joinRegistryBlock from '@/components/JoinRegistry.vue';
import VueObserveVisibility from 'vue-observe-visibility';

export default {
    components: {
        'search-bar' : SearchBar,
        'join-cta' : joinRegistryBlock,
        /* eslint-disable-next-line */
        VueObserveVisibility
    },
    data () {
        return {
            title : 'Registries Directory',
            summary: 'Learn more about the individuals impacted by the 9/11 attacks and the 1993 World Trade Center bombing and explore memorials across the globe that commemorate victims of the attacks.',
            profilesTotal : '15,830',
            summaryTitle : 'Registered Registry Profiles',     
            background : '',
            photocredits : 'Photo Credits: Photograph by Andrea Booher, Here is New York Collection, New-York Historical Society, Gift of Here is New York.Pentagon; Photograph courtesy of the U.S. Department of Defense.Fresh Kills Landfill; Photograph by Mike Segar, Reuters.Flight 93 Crash Site; Flight 93 National Memorial. Photograph courtesy of the Laurel Highlands Visitors Bureau.',
            directory: [],
            index: null
        }
    },
    mounted () {
        // dynamically load in data from json file  
        this.getDirectoryData();
    },   
    methods: {
    getDirectoryData() {
        // this.axios.get('/assets/json-main/' + this.$route.name + '-directory.json')
        this.axios.get('/assets/json-main/' + this.$route.name + '-directory.json')
            .then((response) => {
                this.title = response.data.title;
                this.summary = response.data.summary;
                this.background = response.data.background;
                this.directory = response.data.directory;
                this.photocredits = response.data.photocredits;
            })
            .catch(function (error) {
                    /* eslint-disable-next-line */
                    currentObj.output = error;
            });
    },
    visibilityChanged: function (isVisible) {
        // console.log(Math.round(entry.intersectionRatio * 100) + '% quote:'+ isVisible);
        if (isVisible && undefined != this.$refs.profileName) {
            /* eslint-disable-next-line */
            this.$refs.profileName.forEach(function (x, index) {
                x.classList.add("text-expand");
                });
            }
    },
    listItemStyle: function(i) {
        var style = {};
        var delayLength = i * 0.1;
        style = {'animation-delay': delayLength + 's', '-webkit-animation-delay': delayLength + 's'};
        return style;
        }    
    }    
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .directory-container {
        display: grid;
        max-width: 1170px;
        margin: 100px auto 0;

        h1 {
            grid-column: 1 / 3;
        }

        .summary {
            grid-column: 1 / 4;
            margin-bottom: 20px;
        }

    
        .site-search {
            grid-column: 1 / 4;
            margin: 0 392px 30px 0;
            display: inline-block;
            position: relative;
            height: 60px;
            padding: 0;
            left: 0;
        }
    }

    .photocredits {
        color: $dark-header-text;
        font-size: 10px;
        margin-bottom: 20px;
        grid-column: 1/3;
        padding-top: 50px;
    }

    .non-kiosk {
        position: relative;
        top: 100px;
        padding: 0;
    }

    .directory-listing {
        grid-column: 1 / 4;
        column-width: 570px;
        margin: 0;
    }

    .directory  {
         li a {
            display: block;
            padding: 0.9375rem 0;
            border-bottom: 1px solid $border-bottom;
            min-height: 1.875rem;
        }

        .profile li {
            display: inline-block;
            list-style-type: none;
            padding-right: 10px;
            vertical-align: middle;
        }

        li a:hover .profile-name {
            -webkit-text-stroke: 0.5px;
            //font-weight: 600;
            //-webkit-text-stroke: 1px #2e7db3;
            //text-shadow:0 0 2px #2e7db3, 0 0 2px #2e7db3, 0 0 2px #2e7db3;
            //text-shadow: 0 0 1px #2e7db3, 0 0 1px #2e7db3;
        }    

        .profile-name {
            width: 220px;
            color: $white;
            font-family: Helvetica;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.081rem;
            text-transform: uppercase;  
        }

        .profile-group {
            width: 200px;
            color: $light-text;
            font-family: Helvetica;
            font-size: 0.875rem;
            font-weight: 300;
            letter-spacing: 0.029rem;        
        }

        .profile-location {
            width: 110px;
            color: $light-text;
            font-family: Helvetica;
            font-size: 0.875rem;
            font-weight: 300;
            letter-spacing: 0.029rem;
        }
     }
     
    .text-expand {
        -webkit-animation: text-expand 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                animation: text-expand 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    }
 
    @-webkit-keyframes text-expand {
        0% {
            letter-spacing: -0.5em;
            -webkit-transform: translateZ(-700px);
                    transform: translateZ(-700px);
            opacity: 0;
            color: $white;
        }
        40% {
            opacity: 0.6;
        }
        100% {
            -webkit-transform: translateZ(0);
                    transform: translateZ(0);
            opacity: 1;
            color: $primary-blue;
        }
    }
</style>