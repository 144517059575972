<template>
    <div>
        <RegistriesHero />   
        <VoicesSep11 v-if="!this.$kioskmode.value" />
        <JoinCommunity v-if="!this.$kioskmode.value" />
        <RegistryDirectory v-if="!this.$kioskmode.value" />
        <FAQ v-if="!this.$kioskmode.value" />        
    </div>
</template>

<script>
import RegistriesHero from '@/components/RegistriesHero.vue';
import VoicesSep11 from '@/components/VoicesSep11.vue';
import JoinCommunity from '@/components/JoinCommunity.vue';
import RegistryDirectory from '@/components/RegistryDirectory.vue';
import FAQ from '@/components/FAQ.vue';

export default {
    components: {
        RegistriesHero,
        VoicesSep11,
        JoinCommunity,  
        RegistryDirectory,
        FAQ        
    },
    mounted: function() {
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss">
@import "@/styles/setup/_reset.scss";
@import "@/styles/setup/_vars.scss";
@import "@/styles/setup/_defaults.scss";

</style>