<template>
    <div class="group-row" v-on:click="onClick($event)" v-on:mouseover="updateTip($event)">             
        <div class="group-label">
            {{ groupData.groupname}}
        </div>

        <div class="figuregroup" style="float:right">
            <div v-for="displayrow in displayRows" v-bind:key="displayrow">
                <div class="figure" v-for="n in 30" v-bind:key="n"></div>        
            </div>
            <div :class="{'lastIconPartial': isLastIconPartial}" v-if="isPartialLastRow">
                <div class="figure" v-for="displayicon in displayIcons" v-bind:key="displayicon"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        groupData: { type: Object },
        graphScale: Number
    },
    methods: {
        onClick: function () {
            this.$emit("changeGroup", this.groupData.groupname);
        },
        updateTip: function () {
            this.$emit("updateTip", this.groupData.total, this.groupData.groupname);
        },
        clearTip: function () {
            this.$emit("updateTip", "", "");
        }
    },
    computed: {
       displayRows: function() {
          // based on the groups total, calculate the number of rows
          // 30 icons can fit in 1 row, where iconsPerValue is total value each icon represents
          // return this.groupData.total % (30 * iconsPerValue);
          return Math.floor(this.groupData.total / (30 * this.graphScale));
       },
       isPartialLastRow: function() {
           // if there are remainder to show in partial last row
           return this.groupData.total % (30 * this.graphScale);
       },
        displayIcons: function() {
           // number of icons remaining to populate "last" row
           return Math.floor((this.groupData.total % (30 * this.graphScale))/this.graphScale)+1;
       },
       isLastIconPartial: function() {
           // last people icon is less than iconsPerValue
           return Math.floor((this.groupData.total % (30 * this.graphScale))%this.graphScale);
       }
    }
}
</script>


<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .group-row {
        display: grid;
        grid-template-columns: 175px auto;
        grid-column-gap: 30px;
        margin: 36px 0;
        color: $primary-blue;
        vertical-align: middle;

        :hover {
            color: $black;
        }
        :hover + .figuregroup {
            filter: $figure-hover;
        }

        .figure {
            float: left;
            cursor: pointer;
        }  
        
        .lastIconPartial div.figure:last-child {
            opacity: 0.5;
            filter:alpha(opacity=50);
        }

        .group-label {
            font-family: Helvetica;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.081rem;
            text-transform: uppercase;
            line-height: 1rem;
        }
    }


    .figuregroup:hover {
        filter: $figure-hover;
    }
    
    .figuregroup:hover ~ .group-label {
        color: $black;
    }
</style>