<template>
    <div class="account-search-wrapper" style="border:2px;">
        <div class="accountButton" v-if="this.$userAuthenticated.value">
            <a :href="this.accountURL" aria-label="My Registry Account" title="My Registry Account" class="std-button">My Registry Account</a></div>

        <div v-if="!this.$userAuthenticated.value">
            <a :href="this.registerURL" aria-label="Join the Registry" title="Join the Registry" class="std-button">Join</a></div>
        <div v-if="!this.$userAuthenticated.value">
            <a :href="this.loginURL" aria-label="Login" title="Login" class="cta-link">
         
                <span class="underline">Login</span></a></div>
        <search-bar setWidth="290px"></search-bar>
    </div>
</template>

<script>
import SearchBar from '@/components/Search.vue';

export default {
    components: {
        'search-bar' : SearchBar
    },
    data () {
        return {
            registerURL : this.$siteURL.value + '/#/registration',
            loginURL: this.$siteURL.value + '/#/login',
            accountURL: this.$siteURL.value + '/#/account'
        }
    },
    mounted: function() {
        // check if registration goes to RRW registration
        if (this.$route.name.indexOf('worker') >= 0) {
            this.registerURL = this.$siteURL.value + '/#/registration/worker';
        }        
    } 
  }
</script>

<style lang="scss">
    .account-search-wrapper {
        display: grid;
        grid-template-columns: 100px 100px 140px;
        margin: 36px 0 0 20px;

        .accountButton {
            grid-column: 1/4;
        }
    }

    .site-search {
      grid-column: 1 / 4;
      margin: 30px 0px;
      display: inline-block;
      position: relative;
      height: 60px;
      float: right;
      padding: 0;
    }

    .cta-link {
        padding-top: 12px;
        padding-bottom: 16px;
    }
</style>