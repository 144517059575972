<template>
  <section class="videosection">
    <h1 v-html="title"></h1>
    <div>
        <join-cta />
        <div class="summary">{{ summary }}</div>
    </div>

    <vue-video-gallery 
        :items="this.videos"
        :slideshow="false"
        :gallery="false"
        :loop="true" 
        :showCaption="false"
        :index="index"
        @close="index = null"
        @on-change="galleryChange"
        @on-open="galleryOpen"
        @on-close="galleryClose"
    >
        <template #icon-next><i class="fa galleryui">&#xf054;</i></template>
        <template #icon-previous><i class="fa galleryui">&#xf053;</i></template>
        <template #close><i class="fa galleryui">&#xf00d;</i></template>
    </vue-video-gallery>

    <div class="videocard"
        v-observe-visibility="{
            callback: visibilityChanged,
            once: true}">
      <div
        class="photocard"
        v-for="(video, videoIndex) in videos"
        :key="videoIndex"
        @click="index = videoIndex"
        :style="{ backgroundImage: 'url(' + video.src + ')' }"
      >
        <div class="article-tag">Play Video</div>
        <img class="article-img" :src="video.thumb" :alt="video.title" />
      </div>
    </div>

  </section>
</template>

<script>
import CoolLightBox from '@/components/CoolLightBox.vue';
import VueObserveVisibility from 'vue-observe-visibility';
import joinRegistryBlock from '@/components/JoinRegistry.vue';

export default {
    components : {
        'vue-video-gallery' : CoolLightBox,
        'join-cta' : joinRegistryBlock,
        /* eslint-disable-next-line */
        VueObserveVisibility
    },
    data () {
        return {
            title : 'Reflecting on<br />Rescue & Recovery',
            summary : 'Explore the videos in this gallery to learn more about the individuals who participated the rescue, recovery, and relief efforts after 9/11.',
            videos: [
                {src: '/assets/img/reflections-video/rescue-recovery-2a.mp4', poster: '/assets/img/reflections-video/rescue-recovery-2a-cover.jpg', thumb: '/assets/img/reflections-video/rescue-recovery-2a-th.jpg', type: 'video/mp4', title:'Rescue and Recovery Worker', autoplay:'true'},
                {src: '/assets/img/reflections-video/rescue-recovery-1a.mp4', poster: '/assets/img/reflections-video/rescue-recovery-1a-cover.jpg', thumb: '/assets/img/reflections-video/rescue-recovery-1a-th.jpg', type: 'video/mp4', title: 'Rescue and Recovery Worker', autoplay:'true'},
                {src: '/assets/img/reflections-video/rescue-recovery-3.mp4', poster: '/assets/img/reflections-video/rescue-recovery-3-cover.jpg', thumb: '/assets/img/reflections-video/rescue-recovery-3-th.jpg', type: 'video/mp4', title: 'Rescue and Recovery Worker', autoplay:'true'}
            ],        
            index: null
        }
    },
    mounted () {
        this.getGalleryData();
    },
    afterRouteUpdate () {
        this.getGalleryData();
    },           
    methods: {
        getGalleryData() {
            var routeName = this.$route.name;
            if (routeName == 'main' || routeName == '') {
                routeName = 'home';
            }
            this.axios.get('/assets/json-main/' + this.$route.name + '-videos.json')
                .then((response) => {
                    this.title = response.data.title;
                    this.summary = response.data.summary;
                    this.videos = response.data.videos;
                })
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
                });
        },        
        visibilityChanged: function (isVisible) {
            if (isVisible) {
                this.$el.querySelectorAll('.photo-img').forEach(x=> x.classList.add("desaturate"));
            }
        },
        galleryOpen: function (idx) {
            // autoplay this video when it opens
            this.$el.getElementsByTagName('video')[idx].play();
        },                
        galleryChange: function (idx) {
            // first make sure all videos are paused
            this.videos.forEach((value, key) => { 
                this.$el.getElementsByTagName('video')[key].pause();
            });
            // auto play new video
            this.$el.getElementsByTagName('video')[idx].play();
        },
        galleryClose: function (idx) {
            this.$el.getElementsByTagName('video')[idx].pause();
        }                
    }
}
</script>

<style lang="scss" scoped>
    .videosection {
        display: grid;
        max-width: 1170px;
        margin: 100px auto 50px;

        .videocard {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(278px, 1fr));
            grid-gap: 30px;
        }

        .photocard {
            position: relative;
            display: block;
            box-sizing: border-box;
            overflow: hidden;
            height: 212px;
            cursor: pointer;

            :hover div {    
                visibility: visible;
                opacity: 0.75;
            }
        }

        img.desaturate { 
            animation: toVideoGray 3s;
        }

        .article-img {
            height: 278px;
            width: 100%;
            object-fit: cover;
            filter: gray;
            filter: grayscale(100%);
        }

        .article-img {
            filter: gray;
            -webkit-filter: grayscale(1);

            max-width: 100%; 
            -moz-transition: all 0.6s ease-in-out;  
            -webkit-transition: all .6s ease-in-out;  
            transition: all 0.6s ease-in-out;
        }

        .article-img:hover {
            filter: none;
            -webkit-filter: grayscale(0);
            -moz-transform: scale(1.1);
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        .article-tag {
            text-align: center;
            position: absolute;
            bottom: 85px;
            width: 100%;
            padding: 10px 0;
            background-color: #000000;
            text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
            color: #ffffff;
            font-family: Helvetica;
            font-size: 0.75rem;
            font-weight: 700;
            letter-spacing: 0.001rem;
            text-transform: uppercase;
            z-index: 100;
            visibility: hidden;
            opacity: 0;
            -webkit-transition: opacity 0.4s, visibility  0.3s;
            transition: opacity 0.4s, visibility  0.3s;
            pointer-events: none;
        }

        video {
            outline: none;
            -webkit-appearance: none;

            :focus {
                outline: none;
                -webkit-appearance: none;
            }
        }
    }
</style>
