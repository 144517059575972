<template>
    <div class="join-cta" v-if="!this.$userAuthenticated.value">
        <a :href="this.registerURL" aria-label="Join" title="Join" class="std-button">Join Registry</a>
        {{ this.$router.name }}
    </div>
</template>

<script>
export default {
    data () {
        return {
            registerURL : this.$siteURL.value + '/#/registration'
        }
    },
    mounted: function() {
        // check if registration goes to RRW registration
        if (this.$route.name.indexOf('worker') >= 0) {
            this.registerURL = this.$siteURL.value + '/#/registration/worker';
        }        
    } 
} 
</script>

<style lang="scss" scoped>
    .join-cta {
        float: right;
        margin-top: 30px;
    }
</style>