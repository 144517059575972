<template>
    <div class="leading-section">
        <h2 v-on:click="gotoPage">Registries</h2>
        <h1>{{ this.title }}</h1>
        <div  class="account-search-wrapper">
            <search-bar setwidth="95%"></search-bar>
        </div>
        <div class="summary">{{ this.summary }}</div>
        <div class="join-button">
            <a :href="this.registerURL" aria-label="Join the Registry" title="Join the Registry" class="std-button">Join the Registry</a>
        </div>
    </div>    
</template>

<script>
import SearchBar from '@/components/Search.vue';

export default { 
    components: {
        SearchBar
    },
    props: {
        title: { type:String, default: "Registries"},
        summary: { type:String, default: "A digital archive"}
    },
    data () {
        return {
            registerURL : this.$siteURL.value + '/#/registration'
        }
    },    
    methods: {
        gotoPage ()  {
            this.$emit ('pagechange', 'home');
        }
    }    
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

   .leading-section {
        display: grid;
        grid-template-columns: 900px 30px 100px auto;
        margin: $leading-section;
        padding-top: 80px;
    }

    .account-search-wrapper {
        grid-column: 1;
        position: relative;
        padding: 0;
        margin: 40px 25px 0 0;
        left: 0;
        display: inline-grid;
    }   

    h1 {
        grid-column: 1 / 4;
        margin-bottom: 40px;
    }

    .summary {
        grid-column: 3;
        width: 480px;

        font-size: 0.938rem;
        font-weight: 300;
        letter-spacing: 0.031rem;
        line-height: 1.25rem;
        margin: 18px 0 0;
        padding: 0;
    }

    .search-bar {
        width: 95%;
    }

    .join-button {
        grid-column: 3 / 5;    
        margin: 8px 0 32px; 
    }

    h2 {
        color: $primary-blue;
        font-family: Helvetica;
        font-size: 1.125rem;
        font-weight: 700;
        cursor: pointer;
        min-width: 1000px;
        margin-left: 5px;
    }
    h2:hover {
         color: $black;
    }

    .selector2 {
        width: 450px;
    }

</style>