<template>
    <div class="panelcard" 
        @click="$emit('panelClick', panelInfo.location)"
    >
        <div class="article-tag">
            {{ panelInfo.location }}
        </div>
        <img class="article-img desaturate" :src="panelInfo.image" :alt="panelInfo.location" />
    </div>
</template>

<script>
export default {
    props: {
        paneldata: Object,
    },
    data () {
        return {
            panelInfo: [
                {
                    location : 'World Trade Center',
                    image :'/assets/img/rescue-recovery/world-trade-center-panel@2x.jpg',
                    imagealt: ''
                }
            ]
        }
    },
    mounted () {
        // initialize data with values passed through properties
        this.panelInfo = this.paneldata;
    }
}
</script>

<style lang="scss">
@import "@/styles/setup/_vars.scss";

   .panelcard {
        position: relative;
        display: block;
        box-sizing: border-box;
        overflow: hidden;
        height: $panelcard-height;
        cursor: pointer;
    }

    .article-img {
        height: $panelcard-article-img-height;
        width: 100%;
        object-fit: cover;
            filter: gray;
            filter: grayscale(100%);
    }

    .article-img {
        filter: gray;
        -webkit-filter: grayscale(1);

        max-width: 100%; 
        -moz-transition: all 0.6s ease-in-out;  
        -webkit-transition: all .6s ease-in-out;  
        transition: all 0.6s ease-in-out;
    }

    .article-img:hover {
        filter: none;
            -webkit-filter: grayscale(0);
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }

    .panelcard:hover .article-tag {
        background-color: #000
    }

    .panelcard:hover div {
        visibility: visible;
        opacity: 0.75;
    }

    .article-tag {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        position: absolute;
        bottom: 170px;
        width: 100%;
        height: 4.688rem;
        line-height: 4.688rem;
        padding: 10px 0;
        background-color: transparent;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        color: #ffffff;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.001rem;
        text-transform: uppercase;

        /* Style for "The Pentag" */
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        letter-spacing: 0.081rem;
        text-transform: uppercase;

        z-index: 100;
        opacity: .75;
        -webkit-transition:  background-color 0.4s ease, opacity 0.4s, visibility  0.3s;
        transition: background-color 0.4s ease, opacity 0.4s, visibility  0.3s;
        pointer-events: none;
    }

    img.desaturate { 
        animation: toGray 3s;
    }

    @keyframes toGray {
        0%    { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
        25%   { -webkit-filter: grayscale(25%); filter: grayscale(25%); }
        50%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
        75%   { -webkit-filter: grayscale(75%); filter: grayscale(75%); }
        100%  { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
    }

</style>