<template>
    <div class="leading">
        <h1>{{ this.title }}</h1>
        <div  class="account-search-wrapper">
            <search-bar setWidth="95%"></search-bar>
        </div>
        <div class="summary">{{ this.summary }}</div>
        <div class="join-button">
            <a :href="this.registerURL" aria-label="Join the Registry" title="Join the Registry" class="std-button">Join the Registry</a>
        </div>
    </div>
</template>

<script>
import SearchBar from '@/components/Search.vue';

export default {
    components: {
        'search-bar' : SearchBar,
    },
    props: {
        title: { type:String, default: "Registries"},
        summary: { type:String, default: "A digital repository"}
    },
    data () {
        return {
            registerURL : this.$siteURL.value + '/#/registration'
        }
    }    
}
</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    .leading {
        display: grid;
        grid-template-columns: 900px 30px auto;
        margin: $leading-section;
        padding-top: 106px;
    }

    .account-search-wrapper {
        grid-column: 1;
        position: relative;
        padding: 0;
        margin: 60px 25px 0 0;
        left: 0;
        display: inline-grid;
    }   

    h1 {
        grid-column: 1 / 3;
        margin: 10px 0 0;
    }

    .summary {
        grid-column: 3;
        width: 470px;

        font-size: 0.938rem;
        font-weight: 300;
        letter-spacing: 0.031rem;
        line-height: 1.25rem;
        margin: 18px 0 0;
        padding: 0;
    }

    .search-bar {
        width: 95%;
    }

    .join-button {
        grid-column: 3;    
        margin: 8px 0 32px;
    }

</style>