<template>
    <article>
        <h2>{{ panelInfo.title }}</h2>
        <div>
            {{ panelInfo.summary }}   
        </div>
    </article>
</template>

<script>
export default {
    props: {
        panelInfo: {
            type: Object
        }
    }
}
</script>