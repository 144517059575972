<template>
    <div>
        <RescueRecoveryPanels />
        <RegistryStats />
        <VoicesSep11 />
        <VideosSept11 />
        <QuotesGallery />
        <RegistryDirectory />
    </div>
</template>

<script>
import RescueRecoveryPanels from '@/components/RescueRecoveryPanels.vue';
import RegistryStats from '@/components/RegistryStats.vue';
import VoicesSep11 from '@/components/VoicesSep11.vue';
import VideosSept11 from '@/components/VideosSept11.vue';
import QuotesGallery from '@/components/QuotesGallery.vue';
import RegistryDirectory from '@/components/RegistryDirectory.vue';

export default {
    components: {
        RescueRecoveryPanels,
        RegistryStats,
        VoicesSep11,
        VideosSept11,
        QuotesGallery,    
        RegistryDirectory        
    },

    mounted: function() {
        window.scrollTo(0,0);

        // https://github.com/vuejs/vue-router/issues/2872  
        // catch error to prevent "NavigationDuplicated" promise error
        //this.$router.push('rescue-recovery-workers').catch();
    }
}
</script>

<style lang="scss">
@import "@/styles/setup/_reset.scss";
@import "@/styles/setup/_vars.scss";
@import "@/styles/setup/_defaults.scss";
</style>