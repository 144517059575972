<template>
  <section>
    <h1>{{ title }}</h1>
    <div>
        <div class="summary">{{ summary }}</div>
    </div>
    <div class="faq-cta">
            <a :href=this.faqurl aria-label="View Frequently Asked Questions" title="View Frequently Asked Questions" class="std-button">View Frequently Asked Questions</a>
    </div>
  </section>
</template>

<script>
export default {
    data () {
        return {
            title : 'FAQs',
            summary : 'Have a Question? Browse through answers to frequently asked questions.',
            faqurl: this.$siteURL.value + '/#/faq',
        }
    }
}
</script>

<style lang="scss" scoped>
    section {
        display: grid;
        max-width: 1170px;
        margin: 100px auto 50px;
    }
    
</style>
