<template>
    <section class="stat-container" v-if="displayStats" >
        <div class="sectionStatsDials statbordertop">
            <div class="statcards">
                <circle-graph
                    v-for="(stats, index) in statsdata"
                    v-bind:key="index"
                    ref="theStat"
                    :stats="stats"
                    v-bind:percent="stats.percent"
                    v-bind:description="stats.description"
                >
                </circle-graph>   
            </div>
        </div>
    </section>    
</template>

<script>
import StatsCircleGraph from './StatsCircleGraph.vue';

export default {
    components: {
        'circle-graph' : StatsCircleGraph
    },
    props: {
        statsLocaton: { type: String, default: "World Trade Center" },
        statsRole: { type: String, default: 'a Government Agency Worker' }
    },    
    data: function () {
        return {
            displayStats: false,
            profilesTotal : '15,830',
            profilesDescription : 'Registered Registry Profiles',
            tipAbout: '',            
            statsdata: []
        }
    },
    methods: {
        updateStatsDisplay(value) {
            this.displayStats = value;
        },
        updateStatFor (newLocation, newGroup) {            
            // load the stats for this location/group
            var newJson = newLocation.replace(/ /g,"-").toLowerCase()+'_'+newGroup.replace(/ /g,"-").toLowerCase();

            this.axios.get('/assets/json-stats/'+newJson+'.json')
                .then((response) => {

                    this.displayStats = true;
                    this.statsdata = response.data.statsdata;
                    this.tipAbout = response.data.tipabout;

                    this.$emit('updateProfileTotal', response.data.profilesTotal, response.data.profilesDescription);
                })
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
            });
        },     
    }  
}
</script>

<style lang="scss">
@import "@/styles/setup/_vars.scss";

    .stat-container {
        display: grid;

        max-width: 1170px;
        margin: 0 auto;
        vertical-align: middle;
        padding-bottom: 180px;
    }

    .sectionStatsDials {
        grid-column: 1;  
        padding-top: 2rem;
        z-index: -20;
    }

    .graphsection {
        grid-column: 1;
        padding-top: 1rem;
    }

    .tipsection {
        grid-column: 2;
    }

    .statbordertop {
        border-top: 0.125rem solid $graph-border;
    }

    .graphindex {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.25rem;
        float: right;
        vertical-align: text-bottom;
        margin-bottom: 6px;
    }
    
    .statcards {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        grid-gap: 30px;
        max-width: 800px;
    }

    .tip-aside {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.938rem;
        font-weight: 300;
        letter-spacing: 0.031rem;
        padding: 54px 0;
        vertical-align: middle;
        text-align: center;
        border-bottom: 0.125rem solid $graph-border;

        h3 {
            color: $black;
            font-family: "GothamBold";
            font-size: 2.063rem;
            font-weight: 700;
            letter-spacing: -0.016rem;
            line-height: 2.5rem;
        }

        .tip-summary {
            margin: 11px 0 36px;
        }
    }

    .tip-about {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.75rem;
        font-weight: 700;
        line-height: 1.125rem;
        margin-top: 58px;
    }

</style>