<template>
    <section>
        <header-kiosk :title=this.title :summary=this.summary v-if="this.$kioskmode.value" />
        <header-site :title=this.title :summary=this.summary v-if="!this.$kioskmode.value" />
        <div class="hero-carousel-container" v-bind:class="{ 'kiosk-container' : this.$kioskmode.value}">
            <div id="hero-image1" ref="photoBkgd">
                <img class="hero-img-photo" ref="mainPhoto" 
                    v-for="(image, index) in images"
                    v-bind:key="index"
                    :src="image.src" 
                    :alt="image.caption"
                />
                <img class="hero-img-photo feature-photo" ref="featurePhoto" 
                    v-for="(image, index) in panels"
                    v-bind:key="index"
                    :src="image.mainImage" 
                    :alt="image.credits"
                />
                <span class="hero-credits" ref="photoCredit" :style="{visibility: this.photocredits ? 'visible':'hidden'}" v-html="this.photocredits" />
            </div>
            <feature-panel 
                v-for="(panelInfo, index) in panels" 
                :paneldata="panelInfo"
                v-bind:key="index"
                v-on:updatehero="updatehero(index)"
                v-on:gotoPage="gotoPage"
                v-on:exithover="exitHover(index)" 
                >
            </feature-panel>               
        </div>
    </section>
</template>

<script>
import HeroFeaturePanel from "@/components/HeroFeaturePanel.vue";
import KioskSummary from "@/components/HeaderKiosk.vue";
import SiteSummary from "@/components/HeaderSite.vue";
/* eslint-disable-next-line */
import { timer } from 'vue-timers';

export default {
    components: {
        'feature-panel' : HeroFeaturePanel,
        'header-kiosk' : KioskSummary,
        'header-site' : SiteSummary
    },
    timers: {
        nextPhoto: { name:'photoTimer', time: 10000, autostart: false, repeat: false },
        stopPhotoEffect: { name:'effectTimer', time: 1500, autostart: false, repeat: false }
    },
    data () {
        return {
            title : 'Registries',
            summary: 'A digital archive of stories from witnesses, survivors, rescue and recovery workers, and other communities impacted by the 9/11 attacks, the 1993 World Trade Center bombing, and the ongoing repercussions of both events.',
            photocredits: '',
            fadelength: 10000,
            panels: [
                {
                    headline: "Explore Deeper",
                    title: 'Witnesses & Survivors',
                    summary: 'Firsthand experiences of the 9/11 attacks and the 1993 World Trade Center bombing.',
                    CTA: 'Learn More',
                    thumbnail: '/assets/img/hero/cta-wtc-witnesses.png',
                    url: this.$siteURL.value + '/#/survivors',
                    hover: 'Explore',
                    mainImage: '/assets/img/hero/wtc-twin-towers.jpg',
                    credits: 'The <i>Twin Towers</i> viewed from the southwest. Photograph taken by Marlene May, Here is New York Collection,<br/>New-York Historical Society, Gift of Here is New York.'
                },
                { 
                    headline: "",  
                    title: 'Rescue & Recovery Workers',
                    summary: 'Stories from the rescue, recovery, and relief efforts after 9/11.',
                    CTA: 'Learn More',
                    thumbnail: '/assets/img/hero/cta-recovery-rescue.png',
                    url: 'rescue-recovery-workers',
                    hover: 'Explore',
                    mainImage: '/assets/img/hero/hard-hats-and-medical-workers-at-medical-aid-station-constance-doyle.jpg',
                    credits: 'Hard hats and medical workers at medical aid station the Marriott hotel can be seen in the background.<br/>Photograph taken by Constance J. Doyle.'
                },
                {
                    headline: "",                        
                    title: 'Memorials',
                    summary: 'Memorials around the world that commemorate the victims of 9/11.',
                    CTA: 'Learn More',
                    thumbnail: '/assets/img/hero/cta-memorial.png',
                    url: this.$siteURL.value + '/#/memorials',
                    hover: 'View Map',
                    mainImage: '/assets/img/hero/national-9_11-pentagon-memorial-patrick-kelley.jpg',
                    credits: 'National 9/11 Pentagon Memorial in Arlington, Virginia.<br/>Photograph taken by Petty Officer 2nd Class Patrick Kelley, U.S. Coast Guard, on September 10, 2014.'
                }            
            ],
            images: [
                {src: '/assets/img/hero/the-pentagon-on-september-11-robert-walker.jpg', caption: 'The Pentagon on September 11, 2001.<br/>Collection 9/11 Memorial Museum, Gift of Special Agent Robert Walker.'},
                {src: '/assets/img/hero/the-goodyear-blimp-flying-above-lower-manhattan-skyline-george-forss.jpg', caption: 'The Goodyear Blimp flying above the lower Manhattan skyline.<br/>Collection 9/11 Memorial Museum, Gift of George Forss.'},
                {src: '/assets/img/hero/ground-zero-on-september-11-kenneth-colon.jpg', caption: 'Ground Zero on September 13, 2001. Photograph taken by Kenneth Colon, New York City Police Department.'},
                {src: '/assets/img/hero/a-memorial-titled-reflect-rosemead-california-johnny-phong.jpg', caption: 'A memorial titled <i>Reflect</i>, Rosemead, California. Photograph taken by Johnny Phong in 2016.'},
                {src: '/assets/img/hero/lower-manhattan-9_11-as-seen-from-the-staten-island-ferry-johan-stylander.jpg', caption: 'Lower Manhattan on 9/11 as seen from the Staten Island Ferry. Collection 9/11 Memorial Museum, Gift of Johan Stylander.'},
                {src: '/assets/img/hero/ground-zero-fred-scholl.jpg', caption: 'Ground Zero. Photograph taken by Fred Scholl, Here is New York Collection,<br/>New-York Historical Society, Gift of Here is New York.'},
                {src: '/assets/img/hero/a-military-helicopter-at-the-pentagon-in-arlington-stephen-boitano.jpg', caption: 'A military helicopter at the Pentagon in Arlington, Virginia, September 14, 2001. Photograph by Stephen J. Boitano, Getty Images'},
                {src: '/assets/img/hero/a-memorial-service-at-the-national-9_11-tbd.jpg', caption: 'Dedication Ceremony for National 9/11 Pentagon Memorial, September 11, 2008. Photograph by Department of Defense Photographers.'}
            ],  
            index: null,
            currentIndex: -1,
            lastIndex: 0
        }
    },
    methods: {
        getHeroData() {
            this.axios.get('/assets/json-main/home-hero.json')
                .then((response) => {
                    this.title = response.data.title;
                    this.summary = response.data.summary;
                    this.fadelength = response.data.fadelength;
                    this.images = response.data.images;

                    // set timer to the supplied fadelength
                    if (this.fadelength > 1000) {
                        this.$timer.time = this.fadelength;
                    }
                    this.startSlide();
                })
                .catch(function (error) {
                        /* eslint-disable-next-line */
                        currentObj.output = error;
                        this.currentIndex = this.images.length;
                        this.startSlide();
                });
        },        
        gotoPage (newPage)  {
            // console.log ('at RegistriesHero, going to '+ newPage);
            if (newPage != '#') {
                if (newPage.indexOf('http') > -1) {
                    location.href = newPage;
                } else {
                    this.$router.push(newPage).catch();
                }
            }
        },
        changeEffect () {
            // Previous earlier version applied a kenburns-like effect to the hero image
            // but this was taken out because of complications with transtions between images
            
            var effectNum = Math.floor(Math.random() * 3);
            switch (effectNum) {
                case 0:
                    // this.isEffectA = true;
                    // this.isEffectB = false;
                    // this.isEffectC = false;
                    this.$refs.mainPhoto[this.currentIndex].classList.add('kenburns-bottom-right');
                    // // console.log("effect A");
                    break;
                case 1:
                    // this.isEffectA = false;
                    // this.isEffectB = true;
                    // this.isEffectC = false;
                    this.$refs.mainPhoto[this.currentIndex].classList.add('kenburns-left');
                    // // console.log("effect B");
                    break;
                default:
                    // this.isEffectA = false;
                    // this.isEffectB = false;
                    // this.isEffectC = true;
                    this.$refs.mainPhoto[this.currentIndex].classList.add('kenburns-top');
                    // // console.log("effect C");
                    break;
            }
            
        },
        updatehero (index) {
            this.$timer.stop('nextPhoto');

            // stop the kenburn scaling 
            // this.isEffectA = this.isEffectB = this.isEffectC = false;
            // reset kenburns effects on image[index]
            this.clearEffects(index);

            this.$refs.featurePhoto[index].classList.add("opaque");
            this.photocredits = this.panels[index].credits;
        },
        exitHover: function (index) {
            //restart timer after exiting hover
            this.changeEffect();
            this.$refs.featurePhoto[index].classList.remove("opaque");
            this.photocredits = this.images[this.currentIndex].caption; 
            this.$timer.start('nextPhoto');
        },
        startSlide: function() {
           this.$timer.start('nextPhoto'); 
           
            this.currentIndex += 1;
            if (this.currentIndex >= this.images.length) { 
                this.currentIndex = 0; }

            this.$refs.mainPhoto[this.currentIndex].classList.add("opaque");
            this.photocredits = this.images[this.currentIndex].caption; 
        },        
        nextPhoto: function() {
            this.$timer.stop('nextPhoto');
            this.lastIndex = this.currentIndex;

            // removed kenburns effect on hero image
            // this.isEffectA = this.isEffectB = this.isEffectC = false;
            this.$refs.mainPhoto[this.lastIndex].classList.remove("opaque");
            //this.$refs.photoCredit.classList.add("hide");
            
            this.currentIndex += 1;
            if (this.currentIndex >= this.images.length) { 
                this.currentIndex = 0; }

            this.$refs.mainPhoto[this.currentIndex].classList.add("opaque");
            this.photocredits = this.images[this.currentIndex].caption;    
            //this.$refs.photoCredit.classList.remove("hide");

            //  retart interval with new effect
            this.changeEffect();
            this.$timer.start('nextPhoto');
            this.$timer.start('stopPhotoEffect');
        },
        stopPhotoEffect: function() {
            // this timer is a delay before resetting kenburns effect on previous photo
            this.$timer.stop("stopPhotoEffect");

            // reset kenburns effects on image[index]
            this.clearEffects(this.lastIndex);
        },
        clearEffects: function(indx) {
            this.$refs.mainPhoto[indx].classList.remove("kenburns-bottom-right");
            this.$refs.mainPhoto[indx].classList.remove("kenburns-left");
            this.$refs.mainPhoto[indx].classList.remove("kenburns-top");            
        },
        prev: function() {
            this.$refs.mainPhoto[this.currentIndex].classList.remove("opaque");

            this.currentIndex -= 1;
            if (this.currentIndex < 0) { 
                this.currentIndex = 0; }

            //this.background = this.images[this.currentIndex];
            this.$refs.mainPhoto[this.currentIndex].classList.add("opaque");
            this.photocredits = this.images[this.currentIndex].caption; 
        },
        getImage: function(src) {
            let imgx = new Image();
            imgx.onload = () => {
                return imgx;
            }
            imgx.src = src;
        }           
    },
    mounted: function() {
        // dynamically load in data from json file  
        this.getHeroData();
    }
}

</script>

<style lang="scss" scoped>
@import "@/styles/setup/_vars.scss";

    h1 {
        grid-column: 1 / 4;
    }

    .hero-carousel-container {
        display: grid;
        grid-template-columns: $hero-grid-template-columns;
        grid-column-gap: 0;
        width: 100%;
        min-height: 624px;
        overflow: hidden;
    }

    .feature {
        grid-column: 1 / 2;
    }

    #hero-image1 {
        grid-column: 2;
        grid-row: 1 / 4;
        position: relative;
        display: block;
        box-sizing: border-box;
        overflow: hidden;  
        background-color: $main-background-color;
        max-width: var(--hero-feature-photo-max-width);
    }

    .hero-img-photo {
        height: $hero-img-photo-height;
        width: 100%;
        max-width: var(--hero-feature-photo-max-width);
        position: absolute;
        object-fit: cover;

        background-blend-mode: multiply;
        background-position: 50% 10%;
        background-repeat: no-repeat;      
        background-size: cover;

        // margin: 0;
        // blend mode optional at this stage; will be used more in the next demo.
        -webkit-transition: opacity 2s ease-in-out;
        -moz-transition: opacity 2s ease-in-out;
        -o-transition: opacity 2s ease-in-out;
        transition: opacity 2s ease-in-out;
        opacity:0;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);        
    }

    .feature-photo {
        -webkit-transition: opacity 0.6s ease-in-out;
        -moz-transition: opacity 0.6s ease-in-out;
        -o-transition: opacity 0.6s ease-in-out;
        transition: opacity 0.6s ease-in-out;
    }
    #hero-image1 img.opaque {
        opacity:1;
        -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        filter: alpha(opacity=1);        
    }

    .hero-credits {
        color: #dcdcdc;
        background-color: rgba(0,0,0,.75);
        padding: 5px 10px;
        font-size: .85rem;
        width: 100%;
        object-fit: cover;
        text-align: center;
        position: absolute;
        bottom: 0;
        
        animation: textfadein 1.5s;
        -moz-animation: textfadein 1.5s; 
        -webkit-animation: textfadein 1.5s;
        -o-animation: textfadein 1.5s; 

        i { font-style: italic;}
        b { font-style: bold}
    }

    .hide {
        visibility: hidden !important;
    }

@keyframes textfadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes textfadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes textfadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

.fadef-enter-active,
.fadef-leave-active  {
  transition: opacity 3s ease-in-out;
}

.fadef-enter-to,
.fadef-leave-to {
  opacity: 1;
}

.fadef-enter {
  opacity: 0;
}

.fadeB-enter {
  opacity: 0;
}
.fadeB-enter-active {
  transition: opacity 3s;
}
.fadeB-leave-active,
.fadeB-leave-to {
  opacity: 0;
}
.fadeB-enter-to {
//  opacity: 1;
}

/* classes for the different effects */
.effects-reset {
       -webkit-transform: none; 
       -moz-transform: none; 
        -ms-transform: none; 
         -o-transform: none; 
            transform: none;
}


.kenburns-bottom-right {
       -webkit-transform: none; 
       -moz-transform: none; 
        -ms-transform: none; 
         -o-transform: none; 
            transform: none;    
  -webkit-animation: kenburns-top 120s ease-out 2s forwards;
          animation: kenburns-top 120s ease-out 2s forwards;
}

.kenburns-left {
        -webkit-transform: none; 
       -moz-transform: none; 
        -ms-transform: none; 
         -o-transform: none; 
            transform: none;
  -webkit-animation: kenburns-left 125s ease-out 2s forwards;
          animation: kenburns-left 125s ease-out 2s forwards;
}

.kenburns-top {
           -webkit-transform: none; 
       -moz-transform: none; 
        -ms-transform: none; 
         -o-transform: none; 
            transform: none;
  -webkit-animation: kenburns-top 125s ease-out 2s forwards;
          animation: kenburns-top 125s ease-out 2s forwards;
}


/**
 * ----------------------------------------
 * animation kenburns-bottom-right
 * ----------------------------------------
 */
 
@-webkit-keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale3d(1,1,1) translate3d(0, 0, 0);
            transform: scale3d(1,1,1) translate3d(0, 0, 0);
    -webkit-transform-origin: 84% 84%;
            transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25) translate3d(20px, 15px, 0px);
            transform: scale3d(1.25, 1.25, 1.25) translate3d(20px, 15px, 0px);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
}
@keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale3d(1,1,1) translate3d(0, 0, 0);
            transform: scale3d(1,1,1) translate3d(0, 0, 0);
    -webkit-transform-origin: 84% 84%;
            transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale3d(1.25, 1.25, 1.25) translate3d(20px, 15px, 0px);
            transform: scale3d(1.25, 1.25, 1.25) translate3d(20px, 15px, 0px);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
}


/**
 * ----------------------------------------
 * animation kenburns-left
 * ----------------------------------------
 */

@keyframes kenburns-left {
  0%   { transform:scale3d(1, 1, 1) translate3d(0, 0, 0); }
  100% { transform:scale3d(1.25, 1.25, 1.25) translate3d(6%, -6%, 0); }
}
@-webkit-keyframes kenburns-left {
  0%   { -webkit-transform:scale3d(1, 1, 1) translate3d(0, 0, 0); }
  100% { -webkit-transform:scale3d(1.25, 1.25, 1.25) translate3d(6%, -6%, 0); }
}


/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */

@keyframes kenburns-top {
  0%   { transform:scale3d(1, 1, 1) translate3d(0, 0, 0); }
  100% { transform:scale3d(1.25, 1.25, 1.25) translate3d(-6%, -6%, 0); }
}
@-webkit-keyframes kenburns-top {
  0%   { -webkit-transform:scale3d(1, 1, 1) translate3d(0, 0, 0); }
  100% { -webkit-transform:scale3d(1.25, 1.25, 1.25) translate3d(-6%, -6%, 0); }
}

</style>