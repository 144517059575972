<template>
  <div class="recovery-panels">
    <header-kiosk :title=this.title :summary=this.summary v-if="this.$kioskmode.value" v-on:pagechange="gotoPage" />  
    <div class="panelselectors">
    <header-site :title=this.title :summary=this.summary v-if="!this.$kioskmode.value" v-on:pagechange="gotoPage" />        
        <div class="panels-container">
            <location-panel
                v-for="(panelInfo, index) in panels" 
                :paneldata="panelInfo"
                v-bind:key="index"
                v-on:panelClick="clickLocationPanel"
                >
            </location-panel>
            <div class="selector-container">
                <barselect ref="barselect"></barselect>
                <div class="selector-content">
                    <div class="selector-field sentance">Who was at </div>
                    <v-select 
                        ref="locationSelect"
                        class="selector-field field-select selector1"
                        v-model="selectedLocation"
                        :clearable="false"
                        :value="this.selectedLocation"
                        :options="[
                            'All Locations',
                            'World Trade Center',
                            'the Pentagon',
                            'Fresh Kills Landfill',
                            'Flight 93 Crash Site'
                        ]"
                        @input="updatedLocation"
                        ></v-select>
                
                    <span class="selector-field sentance" >working as </span>
                    
                    <v-select class="selector-field field-select selector2"
                    v-model="selectedProfile"
                    :value="computedProfile"
                    :clearable="false"
                    ref='profileSelect'
                    @input="updateSelectedStat">
                        <option v-for="(option, oIndex) in options" v-bind:value="option" v-bind:key="oIndex">
                            {{ option }}
                        </option>
                    </v-select>
                    <span class="qmark">?<span>
                </div>
            </div>
        </div>      
    </div>
    <div class="counterWrapper leading">
        <div id="odometer" class="odometer totalnumber summary-left">
            {{ profilesTotal }}</div>
        <div class="summary-right">
            <div v-html="getStatsHeadlineCopy"></div>
            <div class="return-cta" v-on:click="returnToLocation()">{{ returnCTA }}</div>
        </div>
    </div>
    <div class="graphWrapper">
        <div class="graphsection"> 
            <div class="graphindex" v-show="displayingProfileGraph"><div class="figure"></div> per {{ graphScale }} value</div>
        </div>
        <div class="tippanel"></div>        
        <transition name="fadeB">
            <profiles-graph class="graph-left" ref="profileGraph" @changeGroup="changeGraphGroup" @tipUpdate="tipUpdate" @graphScale="setGraphScale" v-on:updateProfileTotal="updateTotalHeader" />
        </transition>
        <transition name="fadeB">
            <registry-stats class="graph-left" ref="statsdiagram" v-on:updateProfileTotal="updateTotalHeader" v-on:turnoffGraphDisplay="turnOffGraph" />
        </transition>
        <graph-tip-about ref="tipPane" v-bind:tipCount="this.tipValue" v-bind:tipInfo="this.tipInfo" v-bind:tipAbout="this.tipAbout" class="graph-right" />
    </div>
  </div>
</template>

<script>
import RescueRecoveryPanelItem from "@/components/RescueRecoveryPanelItem.vue";
import vSelect from 'vue-select';
/* eslint-disable-next-line */
import VueObserveVisibilityPlugin from 'vue-observe-visibility';
import * as Odometer from '../assets/js/odometer.js';
import ProfilesBarGraph from '@/components/ProfilesBarGraph.vue';
import RegistryStats from '@/components/RegistryStats.vue';
import GraphTipAbout from '@/components/GraphTipAbout.vue';
import { isNull } from 'util';

import KioskSummary from "@/components/HeaderKioskRRW.vue";
import SiteSummary from "@/components/HeaderSiteRRW.vue";

export default {
    props: {
        propTotal: Number, 
        propSummary: String,
        propStatsData: Object,
        mlocation: String,
        group: String
    },    
    components: {
        'location-panel' : RescueRecoveryPanelItem,
        'v-select' : vSelect,        
        'profiles-graph' : ProfilesBarGraph,
        'registry-stats' : RegistryStats,
        'graph-tip-about' : GraphTipAbout, 
        'header-kiosk' : KioskSummary,
        'header-site' : SiteSummary
    },            
    data () {
        return {
            //title : '<span class="nowrap">Rescue & Recovery</span> Workers',
            title : 'Rescue & Recovery Workers',
            summary: 'A registry of participants in the rescue, recovery, and relief efforts after 9/11 in New York City; Arlington, Virginia; and Somerset County, Pennsylvania.',
            profilesTotal : 15830,
            profilesDescription : '',  
            selectedLocation : 'All Locations',
            selectedProfile : 'All Workers',
            tweenedNumber: 0,
            currentGroup: null,
            scrollOffset: 0,
            returnCTA: 'Show All Workers from All Locations',
            displayingProfileGraph: true,
            tipValue: '',
            tipInfo: '',
            tipAbout: '',
            graphScale: 0,
            panels: [
                {
                    location: "World Trade Center",
                    image :'/assets/img/rescue-recovery/world-trade-center-panel@2x.jpg',
                    imagealt: 'Credit: Photograph by Andrea Booher, Here is New York Collection, New-York Historical Society, Gift of Here is New York.',
                    url: '#'
                },
                {
                    location: "the Pentagon",
                    image :'/assets/img/rescue-recovery/pentagon-panel@2x.jpg',
                    imagealt: 'Credit: Photograph courtesy of the U.S. Department of Defense.',
                    url: '#'
                },
                {
                    location: "Fresh Kills Landfill",
                    image :'/assets/img/rescue-recovery/fresh-kills-land-panel@2x.jpg',
                    imagealt: 'Credit: Photograph by Mike Segar, Reuters.',
                    url: '#'
                },
                {
                    location: "Flight 93 Crash Site",
                    image :'/assets/img/rescue-recovery/flight-93-panel@2x.jpg',
                    imagealt: 'Credit: Flight 93 National Memorial. Photograph courtesy of the Laurel Highlands Visitors Bureau.',
                    url: '#'
                }                  
            ],
            optionsData: {
                alloptions: [
                            'All Workers',
                            'a Building Trades Worker',
                            'a Firefighter',
                            'a Government Agency Worker',
                            'a Law Enforcement Personnel',
                            'a Medical Worker',
                            'a Military Member',
                            'a Public and Private Sector Professional',
                            'a Relief Workers and Volunteer',
                            'Other'],
                options1: [
                            'All Workers',
                            'a Firefighter',
                            'a Government Agency Worker',
                            'a Law Enforcement Personnel',
                            'a Medical Worker',
                            'a Military Member',
                            'a Public and Private Sector Professional',
                            'a Relief Workers and Volunteer',
                            'Other'],

            },
            aboutgroup : [
                { groupname : "building trades worker", displayname: "Construction, Engineering, and Building Trades Professionals", definition : "Registrants whose primary affiliation during the rescue and recovery period was with organizations devoted to the construction and repair of buildings, or with industrial, construction, or electrical trade unions."},
                { groupname : "building trades workers", displayname: "Construction, Engineering, and Building Trades Professionals", definition : "Registrants whose primary affiliation during the rescue and recovery period was with organizations devoted to the construction and repair of buildings, or with industrial, construction, or electrical trade unions."},
                { groupname : "firefighters", displayname: "Firefighters", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a volunteer, private, or government-affiliated fire department, patrol, or rescue squad."},
                { groupname : "government agency workers", displayname: "Government Agency Employees", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a volunteer, private, or government-affiliated fire department, patrol, or rescue squad."},
                { groupname : "law enforcement personnels", displayname: "Law Enforcement Personnel", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a volunteer, private, or government-affiliated fire department, patrol, or rescue squad."},
                { groupname : "medical workers", displayname: "Medical Personnel", definition : "Registrants whose primary affiliation during the rescue and recovery period was with an organization devoted to physical healthcare. This category includes private and public hospital and medical center employees, paramedics, emergency medical services, ambulance workers, first aid and rescue squad members, medical examiners, and veterinarians."},
                { groupname : "military members", displayname: "Military Members", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a branch of the armed forces."},
                { groupname : "others", displayname: "Other", definition : "Registrants who do not fall into any of the above categories, or who had no affiliation to any organized entity and participated in the rescue and recovery period as a private individual."},
                { groupname : "public and private sector professionals", displayname: "Private Sector Professionals", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a for-profit or non-profit private company or organization. This category includes, but is not limited to, communication and energy professionals, cleaning agency employees, equipment supply and rental employees, financial corporation employees, and others who participated in a variety of recovery efforts."},
                { groupname : "public & private sector professionals", displayname: "Private Sector Professionals", definition : "Registrants whose primary affiliation during the rescue and recovery period was with a for-profit or non-profit private company or organization. This category includes, but is not limited to, communication and energy professionals, cleaning agency employees, equipment supply and rental employees, financial corporation employees, and others who participated in a variety of recovery efforts."},
                { groupname : "relief workers and volunteers", displayname: "Relief Workers, Mental Health Professionals, and Volunteers", definition : "Registrants who volunteered during the rescue and recovery period or whose primary affiliation was with an organization dedicated to food services, therapy or emotional support, mental health services, emergency supplies procurement and distribution, religious services, and other services tailored to comfort and well-being."},
            ]        
        }
    },
    mounted () {
        this.$refs.profileSelect.options = this.optionsData.alloptions;
        //if (this.$kioskmode.value)   this.scrollOffset = -140;

        // console.log ("scrolloffset: " + this.scrollOffset);
        var el =  this.$el.querySelector('#odometer');
        /* eslint-disable-next-line */
        var od = new Odometer({
            el: el,
            value: 12334,
            digits: 6,

            // Any option (other than auto and selector) can be passed in here
            format: '(,ddd)',
            theme: 'default'
        });
    },    
    methods: { 
        gotoPage ()  {
            // console.log ('at  RescueRecoveryPanels, next to ' + 'home');
            this.$router.push('/').catch();
        },
        clickLocationPanel (value) {
            // update Locatation selector
            var newLocation = value;
            if (newLocation == 'The Pentagon')  newLocation = 'Pentagon';
            this.selectedLocation = newLocation;

            // reset the Profile Group selected
            this.selectedProfile = this.currentGroup = "All Workers";
            this.$refs.statsdiagram.updateStatsDisplay(false);

            this.selectedLocation = newLocation;
            this.updatedLocation(newLocation);
        },
        setGraphScale: function (scaleValue) {
            this.graphScale = scaleValue;
        },
        changeGraphGroup : function (ev) {
           
            var newGroup = this.getGroupSinglueName(ev);
            this.currentGroup = newGroup;
            this.updateSelectedStat(newGroup);          
        },
        returnToLocation () {
            this.selectedProfile = 'All Workers';
            this.updatedLocation(this.selectedLocation);
        },
        updatedLocation (value) {
            this.$refs.profileSelect.options = this.getOptions;

            // check if there's selection made for profiles
            if (this.selectedProfile != '' ) {
                // check if selected profile exists in new set, if not default to "Any"
                if (!this.$refs.profileSelect.options.includes(this.selectedProfile)) {
                    this.selectedProfile = "All Workers";
                }
                this.updateSelectedStat (this.selectedProfile);
            } else {
                this.returnCTA = "";
            
                this.scrolltograph();
                this.$refs.profileGraph.getGraphProfileData(value);
            }
            this.tipUpdate("","");
        },
        updateSelectedStat (value) {
            if (value==null || value=='All Workers') {
                this.selectedProfile = "All Workers";
                //this.$refs.profileSelect.value = null;
                
                this.$refs.profileGraph.turnoffGraphDisplay(true);
                this.displayingProfileGraph = true;

                // scroll so selection is at top
                this.scrolltograph();

                this.returnCTA = "Show All Workers from All Locations";
                this.$refs.statsdiagram.updateStatsDisplay(false);
                this.$refs.profileGraph.getGraphProfileData(this.selectedLocation);
                this.tipUpdate("","");

                 // scroll so selection is at top
                this.scrolltograph();
            } else {
                this.updateReturnCTA(value);

                // hide the profile bar graph
                this.$refs.profileGraph.turnoffGraphDisplay(false);
                this.displayingProfileGraph = false;

                // scroll so selection is at top
                this.scrolltograph();

                this.selectedProfile = value;
                this.$refs.statsdiagram.updateStatFor(this.selectedLocation, value);  

                 // scroll so selection is at top
                this.scrolltograph();
            } 
        },
        scrolltograph() {
            // need to offset because of the top navigation
            //var offsetPosition = this.$el.getElementsByTagName('barselect')[0].getBoundingClientRect().top - 10;
            //this.topOffset = this.$el.getElementsByTagName('barselect')[0].scrollTop;

            // scroll to <barselect> by calculating it's position, which should already be offsetted
            this.scrollOffset = this.$refs.barselect.getBoundingClientRect().top + window.pageYOffset; 
            window.scrollTo({
                top: this.scrollOffset,
                behavior: 'smooth',
            });
        },
        /* eslint-disable-next-line */
        updateReturnCTA(value) {
            var returnLabel = "";
            returnLabel = "Show All Workers from " + this.selectedLocation;
            this.returnCTA = returnLabel;
        },
        updateTotalHeader (newTotal,newTotalDescription) {
            this.$el.querySelector( '#odometer').innerHTML = newTotal;
            this.profilesDescription = newTotalDescription;
            this.tipUpdate(newTotal, newTotalDescription);
        },
        turnOffGraph () {
            this.$refs.profileGraph.turnoffGraphDisplay(false);
            this.displayingProfileGraph = false;
        },
        tipUpdate (tipcount, tipgroup) {
            if (tipcount == "" || tipgroup=="All Workers") {
                this.tipValue = this.tipInfo = this.tipAbout = "";

            } else {
                // find matching definition
                var indx = this.aboutgroup.findIndex(x => x['groupname'] === tipgroup.toLowerCase());
                if (indx > -1) {
                    this.tipValue = tipcount.toString();
                    this.tipInfo = "people have registered as<br/>" + this.aboutgroup[indx].displayname;
                    this.tipAbout = this.aboutgroup[indx].definition;
                } else {
                    this.tipValue = this.tipInfo = this.tipAbout = "";
                }
            }
        },
        getGroupSinglueName(ev) {
            var newGroup;
            switch(ev) {
                case "Building Trades Workers":
                    newGroup = "a Building Trades Worker"; break;
                case "Firefighters":
                    newGroup = "a Firefighter"; break;
                case "Government Agency Workers":
                    newGroup = "a Government Agency Worker"; break;
                case "Law Enforcement Personnels":
                    newGroup = "a Law Enforcement Personnel"; break;
                case "Law Enforcement Personnel":
                    newGroup = "a Law Enforcement Personnel"; break;
                case "Medical Workers":
                    newGroup = "a Medical Worker"; break;
                case "Military Members":
                    newGroup = "a Military Member"; break;
                case "Public and Private Sector Professionals":
                    newGroup = "a Public and Private Sector Professional"; break;
                case "Relief Workers and Volunteers":
                    newGroup = "a Relief Workers and Volunteer"; break;
                case "Other":
                    newGroup = "Other"; break;
                default:
                    newGroup = "All Workers";
            }
            return newGroup;
         },
        getGroupPluralName(ev) {
            var newGroup;
            switch(ev) {
                case "a Building Trades Worker":
                    newGroup = "Building Trades Workers"; break;
                case "a Firefighter":
                    newGroup = "Firefighters"; break;
                case "a Government Agency Worker":
                    newGroup = "Government Agency Workers"; break;
                case "a Law Enforcement Personnel":
                    newGroup = "Law Enforcement Personnels"; break;
                case "Law Enforcement Personnel":
                    newGroup = "Law Enforcement Personnels"; break;
                case "a Medical Worker":
                    newGroup = "Medical Workers"; break;
                case "a Military Member":
                    newGroup = "Military Members"; break;
                case "a Public and Private Sector Professional":
                    newGroup = "Public and Private Sector Professionals"; break;
                case "a Relief Workers and Volunteer":
                    newGroup = "Relief Workers and Volunteers"; break;
                case "Other":
                    newGroup = "Other"; break;
                default:
                    newGroup = "All Workers";
            }
            return newGroup;
         }
     },
     computed: {
         computedProfile: function() {
             if (!isNull(this.currentGroup)) {
               // // console.log ("computed profile: " + this.currentGroup);
                return this.currentGroup; 
             } else {
                 return null;
             }
         },
         getStatsHeadlineCopy: function() {
             var headlineCopy = "Profiles <br />for ";
             if (this.profilesDescription != 'Registry Profiles') {
                 headlineCopy = "Profiles for " + this.profilesDescription + "<br />at ";
             }
            headlineCopy = headlineCopy + this.selectedLocation;
            return headlineCopy;
         },
         getOptions: function() {
             let options = '';
             if (this.selectedLocation == 'the Pentagon' || this.selectedLocation == 'Flight 93 Crash Site') {    
                    options = this.optionsData.options1;
                } else {
                    options = this.optionsData.alloptions;
                }
                return options;
             }
         }
    }
</script>

<style lang="scss">
@import "@/styles/setup/_vars.scss";

/* odometer styling */
    .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        position: relative;
    }
    .odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        position: relative;
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
        display: inline-block;
        vertical-align: middle;
        *vertical-align: auto;
        *zoom: 1;
        *display: inline;
        visibility: hidden;
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
        text-align: left;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
        display: block;
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
        display: block;
        -webkit-backface-visibility: hidden;
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
        display: block;
        -webkit-transform: translateZ(0);
    }
    .odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
        position: absolute;
    }
    .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform 2s;
        -moz-transition: -moz-transform 2s;
        -ms-transition: -ms-transform 2s;
        -o-transition: -o-transform 2s;
        transition: transform 2s;
    }
    .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform 2s;
        -moz-transition: -moz-transform 2s;
        -ms-transition: -ms-transform 2s;
        -o-transition: -o-transform 2s;
        transition: transform 2s;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    .odometer.odometer-auto-theme, .odometer.odometer-theme-default {
        font-family: "Helvetica Neue", sans-serif;
        line-height: 1.1em;
    }
    .odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
        text-align: center;
    }

    .odometer-inside {
        white-space: nowrap;
    }
/* end odometer styling */

    .fadeB-enter {
        opacity: 0;
    }
    .fadeB-enter-active {
        transition: all 1s ease-out;
    }
    .fadeB-leave-active,
    .fadeB-leave-to {
        opacity: 0;
    }
    .fadeB-enter-to {
        opacity: 1;
    }

    .recovery-panels {
        h1 {
            margin: 0;
            text-align: left;
        }

        .summary {
            margin: 20px 0 10px;
        }
        .disclaimer {
            width: 48.125rem;        
        }

        .qmark {
            margin: 0 8px;
        }

        .account-search-wrapper {
            float: right;
            padding: 0 20px 0 50px;
            margin: 55px 0 0 0;
            display: inline-grid;
        }

        .leading {
            max-width: 1170px;
            margin: 15px auto 50px;
            min-width: 1000px;
        }

        .panelselectors {
            z-index: 500;
            position: relative;
        }

        .graphWrapper {
            display: grid;
            grid-template-columns: 865px 260px;
            grid-column-gap: 30px;
            justify-content: left;
            max-width: 1170px;
            margin: 20px auto 0;    
            border-bottom:  0.125rem solid $graph-border;
            filter: contrast(1);            
        }

        .graph-left {
            grid-column: 1;  
        }

        .graph-right {
            grid-column: 2;
        }

        .tippanel {
            height: 44px;
        };

        .panels-container {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            grid-column-gap: 0;
            width: 100%;
            height: $panelcard-height;
            margin-bottom: 250px;
        }

        barselect {
            top: -30px;
            position: relative;
        }
        
        .selector-container {
            height: 10.938rem;
            width: 100%;
            background-color: $selection-container;
            grid-column: 1 / 5;

            opacity: 0.9;
            color: #fff;
            font-family: "GothamMedium";
            font-size: 2.063rem;
            font-weight: 500;
            letter-spacing: -0.016rem;
            line-height: 2.5rem;

            display: flex;
        }

        .selector-field {
            float: left;
        }

        .sentance {
            padding: 0 20px
        }

        .selector-content {
            margin: auto;
            min-width: 1300px;
        }

        .field-select {
            /* Style for "button-sec" */
            border-radius: 4px;
            background-color: $selection-text;
            color: $primary-blue;
            font-family: Helvetica;
            font-size: 1.125rem;
            font-weight: 700;
        }

        .vs__selected {
            font-size: 18px;
        }

        .selector1 {
            width: 270px;
        }

        .selector2 {
            width: 420px;
        }
    }
    
    .counterWrapper {
        color: $dark-header-text;
        font-family: "GothamMedium";
        font-size: 2.25rem;
        font-weight: 500;
        letter-spacing: -0.062rem;
        line-height: 2.125rem;
        display: flex;
        align-items: flex-end;
        filter: contrast(1);

        .totalnumber {
            font-size: 5.625rem;
            font-weight: 700;
            line-height: 5.313rem;
            margin-right: 20px;
            text-align: right;
        }        

        .summary-left {
            position: relative;
            float: left;
            z-index: -100;
        }

        .summary-right {
            text-align: left;
            float: left;
            width: 900px;
            color: $header-copy;
        }

        .return-cta {
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0.081rem;
            color: $primary-blue;
            margin-top: 20px;
            height: 1rem;
            cursor: grab;

            &:hover {
                font-weight: 800;
            }
        }
    }

    div.counterWrapper.leading {
        margin-bottom: 0;
    }

    .graphindex {
        color: $light-text;
        font-family: Helvetica;
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 1.25rem;
        float: right;
        height: 44px;
        vertical-align: text-bottom;
    }

    .figure {
        margin: 0px 10px 20px 0px;        
        float: left;
        position: relative;
        top: -5px;
        z-index:-100;
    }   

    .odometer {
        width: 300px;
        margin-bottom: 18px;
    }

    .totalValue {
            font-size: 5.625rem;
            font-weight: 700;
            line-height: 5.313rem;        
    }
    
</style>